import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EllipsisVerticalIcon, XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import * as Realm from "realm-web";

const Dashboard = ({ setLoggedIn }) => {
  const [time, setTime] = useState(() => parseInt(localStorage.getItem('timerTime')) || 0);
  const [isActive, setIsActive] = useState(() => localStorage.getItem('timerIsActive') === 'true');
  const [startTimestamp, setStartTimestamp] = useState(() => parseInt(localStorage.getItem('timerStartTimestamp')) || null);
  const [isStopped, setIsStopped] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [timeEntries, setTimeEntries] = useState([]);
  const [formData, setFormData] = useState({
    date: '',
    startTime: '',
    endTime: '',
    customer: '',
    project: '',
    activity: ''
  });
  const [employee, setEmployee] = useState(null);
  const [totalWorkedTime, setTotalWorkedTime] = useState(0);
  const [weeklyGoalPercentage, setWeeklyGoalPercentage] = useState(0);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingEntry, setEditingEntry] = useState({});
  const [comment, setComment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [filter, setFilter] = useState('Heute');
  const [showEditOptions, setShowEditOptions] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [addedTime, setAddedTime] = useState('');
  const [isPaused, setIsPaused] = useState(() => localStorage.getItem('timerIsPaused') === 'true');
  const [resumeTimestamp, setResumeTimestamp] = useState(() => parseInt(localStorage.getItem('timerResumeTimestamp')) || null);
  const [isLoading, setIsLoading] = useState(false);
  const [manualPauseTime, setManualPauseTime] = useState(0);
  const [pauseStartTimestamp, setPauseStartTimestamp] = useState(null);

  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (employee) {
      console.log('Employee projects:', employee.projects);
      console.log('Projects type:', typeof employee.projects);
      if (employee.projects) {
        Object.entries(employee.projects).forEach(([id, name]) => {
          console.log(`Project ${id}:`, name, 'type:', typeof name);
        });
      }
    }
  }, [employee]);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    validateToken(token)
      .then(async isValid => {
        if (!isValid) {
          if (stayLoggedIn) {
          const refreshed = await refreshAccessToken();
          if (!refreshed) {
            setLoggedIn(false);
            navigate('/login');
          } else {
            const newToken = app.currentUser.accessToken;
            const isValidNewToken = await validateToken(newToken);
            if (!isValidNewToken) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              fetchEmployeeData();
              setLoggedIn(true);
            }
          }
        } else {
          setLoggedIn(false);
          navigate('/login');
        }
      } else {
          setLoggedIn(true);
        }
      })
      .catch(error => {
        console.error('Error validating token:', error);
        setLoggedIn(false);
        navigate('/login');
      });
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              console.log(result);
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  useEffect(() => {
    if (employee) {
      const savedTime = parseInt(localStorage.getItem(`timerTime_${employee.employeeId}`)) || 0;
      const savedIsActive = localStorage.getItem(`timerIsActive_${employee.employeeId}`) === 'true';
      const savedStartTimestamp = parseInt(localStorage.getItem(`timerStartTimestamp_${employee.employeeId}`)) || null;
      const savedStartTime = localStorage.getItem(`startTime_${employee.employeeId}`) || '';
  
      setTime(savedTime);
      setIsActive(savedIsActive);
      setStartTimestamp(savedStartTimestamp);
      setStartTime(savedStartTime);
  
      setFormData((prevData) => ({
        ...prevData,
        date: new Date(savedStartTimestamp).toISOString().substring(0, 10),
        startTime: savedStartTime,
      }));
    }
  }, [employee]);

  const fetchEmployeeData = async () => {
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token }),
      redirect: 'follow'
    };

    try {
      const response = await fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching employee data:', result.error);
      } else {
        console.log(result);
        setEmployee(result);

        const timerResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimerDate', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token, employeeId: result.employeeId }),
        });

        const timerResult = await timerResponse.json();

        console.log(timerResult)

        if (timerResult && timerResult.startDate) {
          const startTimestamp = new Date(timerResult.startDate).getTime();
          const elapsedTime = Math.floor((Date.now() - startTimestamp) / 1000);
        
          setStartTimestamp(startTimestamp);
          setTime(elapsedTime);
          setIsActive(true);
        
          localStorage.setItem(`timerStartTimestamp_${result.employeeId}`, startTimestamp);
          localStorage.setItem(`timerTime_${result.employeeId}`, elapsedTime);
          localStorage.setItem(`timerIsActive_${result.employeeId}`, 'true');
        
          const savedStartTime = new Date(startTimestamp).toTimeString().substring(0, 8);
          setStartTime(savedStartTime);
          localStorage.setItem(`startTime_${result.employeeId}`, savedStartTime);
        
          setFormData((prevData) => ({
            ...prevData,
            date: new Date(startTimestamp).toISOString().substring(0, 10),
            startTime: savedStartTime,
          }));
        } else {
          const keysToDelete = [
            `timerStartTimestamp_${result.employeeId}`,
            `timerTime_${result.employeeId}`,
            `timerIsActive_${result.employeeId}`,
            `startTime_${result.employeeId}`
          ];
          keysToDelete.forEach(key => localStorage.removeItem(key));

          setStartTimestamp(null);
          setTime(0);
          setIsActive(false);
          setStartTime('');
          setFormData((prevData) => ({
            ...prevData,
            date: '',
            startTime: '',
          }));
        }
    }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchClients = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getClients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching clients:', result.error);
      } else {
        setClients(result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchProjects = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getProjects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching projects:', result.error);
      } else {
        setProjects(result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchTasks = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching tasks:', result.error);
      } else {
        setTasks(result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchClients();
    fetchProjects();
    fetchTasks();
  }, []);

  const fetchTimeEntries = async () => {
    const token = localStorage.getItem('accessToken');
    if (!employee) return;

    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token, email: employee.email }),
      redirect: 'follow'
    };

    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching time entries:', result.error);
      } else {
        console.log('Time entries:', result);
        setTimeEntries(result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchTimeEntries();
    localStorage.setItem('company', employee?.company)
  }, [employee]);

  useEffect(() => {
    if (employee && timeEntries.length > 0) {
      calculateTotalWorkedTime(timeEntries);
    }
  }, [employee, timeEntries]);

  const calculateTotalWorkedTime = (entries) => {
    const startOfWeek = new Date();
    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);
    endOfWeek.setHours(23, 59, 59, 999);
  
    let totalSeconds = 0;
  
    const filteredEntries = entries.filter(entry => entry.status === 'approved' || !entry.status);
  
    filteredEntries.forEach(entry => {
      const entryDate = new Date(entry.date);
      if (entryDate >= startOfWeek && entryDate <= endOfWeek) {
        console.log(entry);
        const startTime = entry.startTime.split(':');
        const endTime = entry.endTime.split(':');
        const startSeconds = parseInt(startTime[0]) * 3600 + parseInt(startTime[1]) * 60;
        const endSeconds = parseInt(endTime[0]) * 3600 + parseInt(endTime[1]) * 60;
        const durationInSeconds = endSeconds - startSeconds;
  
        const pauseTimeArray = typeof entry.pause === 'string' && entry.pause ? entry.pause.split(':').map(Number) : [0, 0];
        const pauseSeconds = (pauseTimeArray[0] * 3600) + (pauseTimeArray[1] * 60);
        totalSeconds += (durationInSeconds - pauseSeconds);
      }
    });
  
    setTotalWorkedTime(totalSeconds);
  
    if (employee) {
      const weeklyGoalSeconds = employee.hours_week * 3600;
      const percentage = (totalSeconds / weeklyGoalSeconds) * 100;
      setWeeklyGoalPercentage(Math.round(percentage));
    }
  };

  useEffect(() => {
    if (!employee) return;

    if (isActive && !isPaused) {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
        localStorage.setItem(`timerTime_${employee.employeeId}`, time + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (isPaused && resumeTimestamp) {
      const now = Date.now();
      if (now >= resumeTimestamp) {
        setIsPaused(false);
        localStorage.setItem(`timerIsPaused_${employee.employeeId}`, false);
        setResumeTimestamp(null);
        localStorage.removeItem(`timerResumeTimestamp_${employee.employeeId}`);
      }
    }
}, [isActive, isPaused, resumeTimestamp, employee, time]);

  const deleteTimerDate = async () => {
    const token = localStorage.getItem('accessToken');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token, employeeId: employee.employeeId }),
    };
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/deleteTimerDate', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error deleting timer date:', result.error);
      } else {
        console.log('Timer date successfully deleted');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addTimerDate = async (startDate) => {
    const token = localStorage.getItem('accessToken');
    const locationName = '';
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token, employeeId: employee.employeeId, startDate, locationName }),
    };
  
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addTimerDate', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error adding timer date:', result.error);
      } else {
        console.log('Timer date added successfully');
      }
    } catch (error) {
      console.error('Error adding timer date:', error);
    }
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.edit-options-dropdown')) {
        setShowEditOptions(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    const getHours = `0${hours}`.slice(-2);
    const getMinutes = `0${minutes}`.slice(-2);
    const getSeconds = `0${seconds}`.slice(-2);
    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
  
    if (name === 'date' && value > new Date().toISOString().split('T')[0]) {
      updatedFormData.date = new Date().toISOString().split('T')[0];
    }
  
    if ((name === 'startTime' || name === 'endTime') && formData.date === new Date().toISOString().split('T')[0]) {
      const currentTime = new Date().toTimeString().split(' ')[0];
      if (value > currentTime) {
        updatedFormData[name] = currentTime;
      }
    }
  
    setFormData(updatedFormData);
  };

  const handleStartStop = async () => {
    const now = new Date();
    const nowISO = now.toISOString();
    const nowTimeString = now.toTimeString().substring(0, 8);
    const currentDate = nowISO.substring(0, 10);
  
    if (isActive) {
      setIsActive(false);
      setIsStopped(true);
  
      const endTimeString = nowTimeString;
      setEndTime(endTimeString);
  
      setFormData(prevData => ({
        ...prevData,
        date: currentDate,
        startTime: prevData.startTime || startTime,
        endTime: endTimeString,
      }));
  
      localStorage.removeItem(`timerIsActive_${employee.employeeId}`);
      localStorage.removeItem(`timerStartTimestamp_${employee.employeeId}`);
      localStorage.removeItem(`timerTime_${employee.employeeId}`);
    } else {
      setIsStopped(false);
      setIsActive(true);
  
      const startTimeString = nowTimeString;
      setStartTime(startTimeString);
  
      setFormData(prevData => ({
        ...prevData,
        date: currentDate,
        startTime: startTimeString,
      }));
  
      localStorage.setItem(`timerStartTimestamp_${employee.employeeId}`, now.getTime());
      localStorage.setItem(`startTime_${employee.employeeId}`, startTimeString);
      localStorage.setItem(`timerIsActive_${employee.employeeId}`, 'true');
  
      await addTimerDate(nowISO);
    }
  };

  const handleResetTime = () => {
    if (!employee) return;
  
    setTime(0);
    setStartTimestamp(null);
    localStorage.removeItem(`timerTime_${employee.employeeId}`);
    localStorage.removeItem(`timerStartTimestamp_${employee.employeeId}`);
    localStorage.setItem(`timerIsActive_${employee.employeeId}`, false);
    setIsActive(false);
    setIsStopped(false);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    console.log(formData)

    const selectedDateTime = new Date(`${formData.date}T${formData.endTime}`);
    const now = new Date();

    if (selectedDateTime > now) {
      alert('Das ausgewählte Datum und die Uhrzeit dürfen nicht in der Zukunft liegen.');
      return;
    }

    if (!employee) {
      console.error('Employee data not available');
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem('accessToken');

    let roundedStartTime = roundToNearestQuarterHour(formData.startTime);
    let roundedEndTime = roundToNearestQuarterHour(formData.endTime);

    if (roundedStartTime.split(':').length === 2) {
      roundedStartTime += ':00';
    }
    if (roundedEndTime.split(':').length === 2) {
      roundedEndTime += ':00';
    }

    const startTimeArray = roundedStartTime.split(':').map(Number);
    const endTimeArray = roundedEndTime.split(':').map(Number);

    const startSeconds = startTimeArray[0] * 3600 + startTimeArray[1] * 60 + startTimeArray[2];
    const endSeconds = endTimeArray[0] * 3600 + endTimeArray[1] * 60 + endTimeArray[2];

    const addedTimeInSeconds = endSeconds - startSeconds;
    console.log(addedTimeInSeconds, startTimeArray, endTimeArray);

    let automaticPauseTime = 0;
  
    const hours = addedTimeInSeconds / 3600;
    console.log(hours)
    if (hours >= 9.5) {
      automaticPauseTime = 45 * 60;
    } else if (hours > 6) {
      automaticPauseTime = 30 * 60;
    }
  
    const totalPauseTimeInSeconds = automaticPauseTime + Math.floor(manualPauseTime / 1000);
    const formattedPauseTime = formatTime(totalPauseTimeInSeconds);

    console.log({
      ...formData,
      startTime: roundedStartTime,
      endTime: roundedEndTime,
      employeeId: employee.employeeId,
      pause: formattedPauseTime,
    })
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        timeSlotData: {
          ...formData,
          startTime: roundedStartTime,
          endTime: roundedEndTime,
          employeeId: employee.employeeId,
          pause: formattedPauseTime,
        },
      }),
      redirect: 'follow'
    };
  
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addTime', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error adding time entry:', result.error);
      } else {
        console.log('Time entry added:', result);
        await fetchTimeEntries();

        await deleteTimerDate();
    
        localStorage.removeItem(`timerTime_${employee.employeeId}`);
        localStorage.removeItem(`timerStartTimestamp_${employee.employeeId}`);
        localStorage.removeItem(`timerIsActive_${employee.employeeId}`);
        localStorage.removeItem(`startTime_${employee.employeeId}`);
        localStorage.removeItem(`timerIsPaused_${employee.employeeId}`);
        localStorage.removeItem(`timerResumeTimestamp_${employee.employeeId}`);

        setIsStopped(false);
        setTime(0);
        setManualPauseTime(0);
        setStartTimestamp(null);
        setIsActive(false);
        setStartTime('');
        setIsSuccess(true);
        setAddedTime(formatTime(addedTimeInSeconds));
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleEditClick = (filteredIndex) => {
    const filteredEntries = filterTimeEntries();
    setEditingIndex(filteredIndex);
    setEditingEntry({ ...filteredEntries[filteredIndex] });
    setComment('');
    setIsModalOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingEntry({ ...editingEntry, [name]: value });
  };

  const handleEditSubmit = async () => {
    const filteredEntries = filterTimeEntries();
    const oldEntry = filteredEntries[editingIndex];
    console.log(oldEntry);
    const token = localStorage.getItem('accessToken');
  
    const roundedStartTime = roundToNearestQuarterHour(editingEntry.startTime);
    const roundedEndTime = roundToNearestQuarterHour(editingEntry.endTime);
  
    const changeRequestData = {
      token,
      timeId: oldEntry.id,
      originalDate: oldEntry.date,
      originalStartTime: oldEntry.startTime,
      originalEndTime: oldEntry.endTime,
      newDate: editingEntry.date,
      newStartTime: `${editingEntry.date}T${roundedStartTime}:00.000Z`,
      newEndTime: `${editingEntry.date}T${roundedEndTime}:00.000Z`,
      comment: comment,
      project: oldEntry.projectId,
      client: oldEntry.customerId,
      task: oldEntry.taskId,
      leadResponse: false
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify(changeRequestData),
      redirect: 'follow'
    };
  
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addChangeRequest', requestOptions);
      const result = await response.json();
      console.log(result);
      if (result.error) {
        console.error('Error creating change request:', result.error);
      } else {
        console.log('Change request created:', result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  
    setEditingIndex(null);
    setEditingEntry({});
    setIsModalOpen(false);
    console.log('Old Entry:', oldEntry);
    console.log('Updated Entry:', editingEntry);
  };

  const closeModal = () => {
    console.log("Modal closed");
    setIsModalOpen(false);
    setEditingIndex(null);
    setEditingEntry({});
  };

  const filterTimeEntries = () => {
    const now = new Date();
    if (filter === 'Heute') {
      return timeEntries.filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate.toDateString() === now.toDateString();
      });
    } else if (filter === 'Letzte Woche') {
      const startOfLastWeek = new Date();
      startOfLastWeek.setDate(now.getDate() - now.getDay() - 6);

      const endOfLastWeek = new Date(startOfLastWeek);
      endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);

      return timeEntries.filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate >= startOfLastWeek && entryDate <= endOfLastWeek;
      });
    } else if (filter === 'Diesen Monat') {
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      return timeEntries.filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate >= startOfMonth && entryDate <= now;
      });
    }
    return timeEntries;
  };

  const toggleEditOptions = (index) => {
    console.log("Toggle edit options for index:", index);
    setShowEditOptions(showEditOptions === index ? null : index);
  };

  const handleCancel = () => {
    setIsStopped(false);
    setIsActive(true);
    localStorage.setItem('timerIsActive', true);
  };

  const handlePause = () => {
    if (!employee) return;
  
    setPauseStartTimestamp(Date.now());
    setIsPaused(true);
    localStorage.setItem(`timerIsPaused_${employee.employeeId}`, true);
  };
  
  const handleResume = () => {
    if (!employee) return;
  
    if (pauseStartTimestamp) {
      const pauseDuration = Date.now() - pauseStartTimestamp;
      setManualPauseTime((prevPauseTime) => prevPauseTime + pauseDuration);
      setPauseStartTimestamp(null);
    }
    setIsPaused(false);
    setStartTimestamp(Date.now());
    localStorage.setItem(`timerIsPaused_${employee.employeeId}`, false);
    localStorage.setItem(`timerStartTimestamp_${employee.employeeId}`, Date.now());
  };

  const roundToNearestQuarterHour = (timeString) => {
    if (!employee.isTrustedEmployee) {
      let [hoursStr, minutesStr, secondsStr] = timeString.split(':');
      let hours = parseInt(hoursStr, 10);
      let minutes = parseInt(minutesStr, 10);
      let seconds = parseInt(secondsStr, 10);
  
      if (isNaN(seconds)) {
        seconds = 0;
      }
      if (isNaN(minutes)) {
        minutes = 0;
      }
      if (isNaN(hours)) {
        hours = 0;
      }
  
      let totalMinutes = minutes + seconds / 60;
      const remainder = totalMinutes % 15;
      let roundedMinutes = totalMinutes;
  
      if (remainder <= 5) {
        roundedMinutes = totalMinutes - remainder;
      } else if (remainder >= 10) {
        roundedMinutes = totalMinutes + (15 - remainder);
      }
  
      if (roundedMinutes >= 60) {
        roundedMinutes -= 60;
        hours += 1;
      }
  
      // if (Math.floor(roundedMinutes) !== minutes) {
      //   seconds = 0;
      // }
  
      return `${String(hours).padStart(2, '0')}:${String(Math.floor(roundedMinutes)).padStart(2, '0')}:${String(
        seconds
      ).padStart(2, '0')}`;
    }
  
    if (timeString.split(':').length === 2) {
      timeString += ':00';
    }
  
    return timeString;
  };

  return (
    <div className="p-[12px] md:py-[48px] md:px-[90px]">
      <header className="bg-gray-100 rounded-md mb-[75px] md:mb-[125px]">
        <h1 className="text-xl md:text-3xl font-[600] md:mb-[12px]">Hallo {employee?.firstName}!</h1>
        <p className="text-gray-700">{new Intl.DateTimeFormat('de-DE', { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date())}</p>
        <div className="flex mt-[24px] md:mt-[48px] space-x-0 md:space-x-2 flex-col md:flex-row md:space-y-0 space-y-4">
          <div className="flex-1 flex flex-col md:flex-row space-x-0 md:space-x-[7.5%] space-y-4 md:space-y-0">
            <div className="flex-1 p-4 flex flex-col items-start gap-4 bg-[#C4D1FF] shadow-md rounded-[16px] max-w-full md:max-w-[270px] md:max-h-[116px]">
              <h2 className="text-lg md:text-[16px] text-[#0B1963] font-[500] md:pl-[15px]">Wochenziel erfüllt:</h2>
              <p className="text-2xl md:text-[36px] font-[600] text-[#0000FF] md:pl-[15px]">{Math.round(weeklyGoalPercentage)}%</p>
            </div>
            <div className="flex-1 p-4 flex flex-col items-start gap-4 bg-[#C4D1FF] shadow-md rounded-[16px] max-w-full md:max-w-[270px] md:max-h-[116px]">
              <h2 className="text-lg md:text-[16px] text-[#0B1963] font-[500] md:pl-[15px]">Diese Woche gearbeitet:</h2>
              <p className="text-2xl md:text-[36px] font-[600] text-[#0000FF] md:pl-[15px]">{formatTime(totalWorkedTime)}</p>
            </div>
          </div>
          <div className="flex flex-col items-center w-full md:max-w-md h-auto justify-between md:h-[200px]">
            {!isStopped ? (
              <>
                <div className="flex flex-col items-center w-full">
                  <div className="hidden md:flex bg-[#4161D0] shadow-md w-full h-[116px] items-center justify-center text-white md:text-[55px] font-mono leading-[75px] rounded-3xl">
                    {formatTime(time)}
                  </div>
                  <div className="flex flex-col w-full mt-4 space-y-2">
                    {/* {isActive && (
                      <button
                        onClick={isPaused ? handleResume : handlePause}
                        className={hidden md:flex bg-blue-700 text-white py-2 px-4 rounded-full shadow-md w-full items-center justify-center}
                      >
                        {isPaused ? 'Timer fortsetzen' : 'Timer pausieren'}
                      </button>
                    )} */}
                    <button
                      onClick={handleStartStop}
                      className={`hidden md:flex bg-blue-700 text-white py-2 px-4 rounded-full shadow-md w-full items-center justify-center ${isActive ? 'bg-red-500' : 'bg-blue-700'}`}
                    >
                      {isActive ? 'Timer stoppen' : 'Timer starten'}
                    </button>
                  </div>
                </div>
              </>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4 w-full">
              <h2 className="text-xl md:text-2xl font-bold mb-4">Erfasste Zeit: {formatTime(time)} H</h2>
                <div>
                  <label className="block text-left">Kunden auswählen</label>
                  <select
                    name="customer"
                    value={formData.customer}
                    onChange={handleChange}
                    className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
                  >
                    <option value="">Kunde auswählen</option>
                    {clients && clients.map((client) => (
                      <option key={client._id} value={client._id}>
                        {String(client?.company?.name || '')}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-left">Projekt auswählen</label>
                  <select
                    name="project"
                    value={formData.project}
                    onChange={handleChange}
                    className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
                  >
                    <option value="">Projekt auswählen</option>
                    {employee && employee.projects && typeof employee.projects === 'object' && 
                      Object.entries(employee.projects).map(([projectId, projectName]) => (
                        <option key={projectId} value={projectId}>
                          {String(projectName || '')}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <label className="block text-left">Tätigkeit auswählen</label>
                  <select
                    name="activity"
                    value={formData.activity}
                    onChange={handleChange}
                    className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
                  >
                    <option value="">Tätigkeit auswählen</option>
                    {tasks && tasks.map((task) => (
                      <option key={task._id} value={task._id}>
                        {String(task?.name || '')}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-between items-center">
                  <div>
                    <button type="submit" className="bg-[#0000FF] text-white py-2 px-4 rounded mr-2">
                      Speichern
                    </button>
                    <button type="button" onClick={handleCancel} className="text-[#0000FF] py-2 px-4 rounded">
                      Abbrechen
                    </button>
                  </div>
                  <button type="button" onClick={handleResetTime} className="text-[#0000FF] py-2 px-4 rounded">
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </header>
  
      <main className="flex flex-col items-center md:flex-row md:items-start">
      {isSuccess ? (
        <div className="w-full md:w-1/2 p-6 bg-[#FCFCFC] rounded-xl shadow-md flex flex-col items-center justify-center h-full md:h-[509px]">
          <CheckCircleIcon className="h-16 w-16 mb-4 text-[#0000FF]" />
          <p className="text-xl font-bold mb-4 text-[#101828]">Zeit hinzugefügt: {addedTime} H</p>
          <p
            className="text-[#101828] underline cursor-pointer"
            onClick={() => {
              setIsSuccess(false);
              setFormData({
                date: '',
                startTime: '',
                endTime: '',
                customer: '',
                project: '',
                activity: ''
              });
            }}
          >
            Neu
          </p>
        </div>
      ) : (
        <div className="w-full md:w-1/2 bg-white shadow-lg mb-[48px] py-[30px] px-[20px] rounded-xl md:h-[509px]">
          <h2 className="text-xl md:text-2xl font-bold mb-4 text-center text-[#0000FF] md:pb-[32px]">Manuell hinzufügen</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                placeholder="Tag auswählen"
                className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
                max={new Date().toISOString().split('T')[0]}
                required
              />
            </div>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              <div className="flex-1">
                <input
                  type="time"
                  step="1"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleChange}
                  placeholder="Startzeit"
                  className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
                  required
                />
              </div>
              <div className="flex-1">
                <input
                  type="time"
                  step="1"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  placeholder="Ende"
                  className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
                  required
                />
              </div>
            </div>
            <div>
              <select
                name="customer"
                value={formData.customer}
                onChange={handleChange}
                className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
              >
                <option value="">Kunde auswählen</option>
                {clients.map((client) => (
                  <option key={client._id} value={client._id}>{client.company.name}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                name="project"
                value={formData.project}
                onChange={handleChange}
                className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
              >
                <option value="">Projekt auswählen</option>
                {employee && Object.entries(employee.projects).map(([projectId, projectName]) => (
                  <option key={projectId} value={projectId}>{projectName}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                name="activity"
                value={formData.activity}
                onChange={handleChange}
                className="w-full border rounded px-3 py-2 md:px-2 md:py-1 h-[40px]"
              >
                <option value="">Tätigkeit auswählen</option>
                {tasks.map((task) => (
                  <option key={task._id} value={task._id}>{task.name}</option>
                ))}
              </select>
            </div>
            <button type="submit" className="bg-[#0000FF] text-white py-4 px-6 rounded-lg flex items-center justify-center mx-auto md:py-2 md:px-4" disabled={isLoading}>
              <span className="mr-2">+</span> Hinzufügen
            </button>
          </form>
        </div>
      )}
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <img src="dashboard-monster.png" alt="Illustration" className="max-w-full h-auto md:w-[70%]" />
        </div>
      </main>
  
      <main className="w-full">
        <h2 className="text-xl md:text-2xl font-bold mt-5 md:mt-10 text-center md:text-left">Meine letzten Zeiten</h2>
        <p className='mb-10 md:mb-[45px]'>Weitere Zeiten unter "Meine Zeiten" im Menü</p>
        <div className="mb-4 w-full md:mb-[48px]">
          <label className="block text-left">Zeitraum Wählen</label>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="w-full border rounded px-3 py-2"
          >
            <option value="Heute">Heute</option>
            <option value="Letzte Woche">Letzte Woche</option>
            <option value="Diesen Monat">Diesen Monat</option>
          </select>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-xl">
            <thead>
              <tr>
                <th className="py-1 md:py-2 px-2 md:px-4">Datum</th>
                <th className="py-1 md:py-2 px-2 md:px-4">Start Zeit</th>
                <th className="py-1 md:py-2 px-2 md:px-4">Ende Zeit</th>
                <th className="py-1 md:py-2 px-2 md:px-4">Kunde</th>
                <th className="py-1 md:py-2 px-2 md:px-4">Projekt</th>
                <th className="py-1 md:py-2 px-2 md:px-4">Aktivität</th>
                {timeEntries.some(entry => entry.status) && <th className="py-1 md:py-2 px-2 md:px-4">Status</th>}
                <th className="py-1 md:py-2 px-2 md:px-4"></th>
              </tr>
            </thead>
            <tbody>
              {filterTimeEntries().map((entry, filteredIndex) => {
                const customerName = clients.find(client => client._id === entry.customerId)?.company?.name || entry.customer;
                const projectName = projects.find(project => project._id === entry.projectId)?.name || entry.project;
                const activityName = tasks.find(task => task._id === entry.taskId)?.name || entry.activity;
  
                return (
                  <tr key={entry.id || filteredIndex} className="text-center border-b">
                    <td className="py-1 md:py-2 px-2 md:px-4">{entry.date}</td>
                    <td className="py-1 md:py-2 px-2 md:px-4">{entry.startTime}</td>
                    <td className="py-1 md:py-2 px-2 md:px-4">{entry.endTime}</td>
                    <td className="py-1 md:py-2 px-2 md:px-4">{customerName}</td>
                    <td className="py-1 md:py-2 px-2 md:px-4">{projectName}</td>
                    <td className="py-1 md:py-2 px-2 md:px-4">{activityName}</td>
                    {entry.status && <td className="py-1 md:py-2 px-2 md:px-4">{
                      entry.status === "pending" ? "Ausstehend" : entry.status === "approved" ? "Bestätigt" : "Abgelehnt"}</td>
                    }
                    <td className="py-1 md:py-2 px-2 md:px-4">
                      <div className="relative">
                        <button
                          onClick={() => toggleEditOptions(filteredIndex)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          <EllipsisVerticalIcon className="h-5 w-5" />
                        </button>
                        {showEditOptions === filteredIndex && (
                          <div className="absolute right-0 bottom-0 bg-white border rounded-md shadow-lg z-20 edit-options-dropdown">
                            <button
                              onClick={() => {
                                handleEditClick(filteredIndex);
                                toggleEditOptions(null);
                              }}
                              className="block text-left px-4 py-2 text-gray-700"
                            >
                              Bearbeiten
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
  
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 md:p-4">
            <h2 className="text-xl md:text-2xl font-bold mb-4">Zeiteintrag Bearbeiten</h2>
            <div className="mb-4">
              <label className="block text-left">Datum</label>
              <input
                type="date"
                name="date"
                value={editingEntry.date}
                onChange={handleEditChange}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-left">Start Zeit</label>
              <input
                type="time"
                name="startTime"
                value={editingEntry.startTime}
                onChange={handleEditChange}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-left">End Zeit</label>
              <input
                type="time"
                name="endTime"
                value={editingEntry.endTime}
                onChange={handleEditChange}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-left">Kommentar</label>
              <textarea
                name="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-[#0000FF] text-white py-1 px-2 md:py-2 md:px-4 rounded mr-2"
                onClick={handleEditSubmit}
              >
                Speichern
              </button>
              <button
                type="button"
                className="text-[#0000FF] py-1 px-2 md:py-2 md:px-4 rounded"
                onClick={closeModal}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;