import React, { useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import SidebarNavigation from './SidebarNavigation';
import Dashboard from './pages/Dashboard';
import Employee from './pages/Employee';
import EmployeeAdministration from './pages/EmployeeAdministration';
import MyTimes from './pages/MyTimes';
import Projects from './pages/Projects';
import './styles.css';
import TimeManagement from './pages/TimeManagement';
import Clients from './pages/Clients';
import Tasks from './pages/Tasks';
import Export from './pages/Export';
import Settings from './pages/Settings';
import AutomatedLogin from './pages/AutomatedLogin';
import PasswordResetForm from './pages/PasswordResetForm'

const App = () => {
  const divRef = useRef(null);
  const token = localStorage.getItem('accessToken');
  const [loggedIn, setLoggedIn] = useState(Boolean(token));

  return (
    <Router>
      <div className="h-screen bg-gray-100">
        {loggedIn ? (
          <div className="h-screen flex">
            <div className="w-1/6">
              <SidebarNavigation setLoggedIn={setLoggedIn} />
            </div>
            <div ref={divRef} className="w-5/6 overflow-scroll bg-gray-100">
              <Routes>
                <Route path="/" element={<Dashboard setLoggedIn={setLoggedIn} />} />
                <Route path="/home" element={<Dashboard setLoggedIn={setLoggedIn} />} />
                <Route path="/employee" element={<Employee setLoggedIn={setLoggedIn} />} />
                <Route path="/employee-administration" element={<EmployeeAdministration setLoggedIn={setLoggedIn} />} />
                <Route path="/projects" element={<Projects setLoggedIn={setLoggedIn} />} />
                <Route path="/clients" element={<Clients setLoggedIn={setLoggedIn} />} />
                <Route path="/tasks" element={<Tasks setLoggedIn={setLoggedIn} />} />
                <Route path="/myTimes" element={<MyTimes setLoggedIn={setLoggedIn} />} />
                <Route path="/time-management" element={<TimeManagement setLoggedIn={setLoggedIn} />} />
                <Route path="/export" element={<Export setLoggedIn={setLoggedIn} />} />
                <Route path="/settings" element={<Settings setLoggedIn={setLoggedIn} />} />
                {/* <Route path="/test" element={<Test setLoggedIn={setLoggedIn} />} /> */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </div>
        ) : (
          <Routes key={loggedIn ? "auth" : "no-auth"}>
            <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
            {/* <Route path="/automatedLogin/:secretKey" element={<AutomatedLogin setLoggedIn={setLoggedIn} />} /> */}
            <Route path="/forgotPassword" element={<PasswordResetForm />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default App;
