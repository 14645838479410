import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RejectionDialog from '../RejectionDialog';
import WarningDialog from '../WarningDialog';
import AbsenceDialog from '../AbsenceDialog';
import EditTimeDialog from '../EditTimeDialog'
import { Oval } from 'react-loader-spinner';
import * as Realm from "realm-web";

const TimeManagement = ({ setLoggedIn }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [timeEntries, setTimeEntries] = useState([]);
  const [allTimeEntries, setAllTimeEntries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [changeRequests, setChangeRequests] = useState({ pending: [], approved: [], denied: [] });
  const [allChangeRequests, setAllChangeRequests] = useState({ pending: [], approved: [], denied: [] });
  const [selectedTab, setSelectedTab] = useState('pending');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [viewMode, setViewMode] = useState('month');
  const [isRejectionDialogOpen, setIsRejectionDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [annualPlan, setAnnualPlan] = useState({});
  const [holidayPlan, setHolidayPlan] = useState([]);
  const [missedHours, setMissedHours] = useState(0);
  const [rosterData, setRosterData] = useState([]);
  const [filteredRosterData, setFilteredRosterData] = useState([]);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [isAbsenceDialogOpen, setIsAbsenceDialogOpen] = useState(false);
  const [totalHoursForDay, setTotalHoursForDay] = useState({ hours: 0, minutes: 0, totalMinutes: 0 });
  const [monthlyTargetHours, setMonthlyTargetHours] = useState(0);
  const [annualOverview, setAnnualOverview] = useState({
    totalTargetHours: 0,
    totalRecordedHours: 0,
    totalDiffHours: 0,
  });
  const [isEditTimeDialogOpen, setIsEditTimeDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [filteredPendingView, setFilteredPendingView] = useState(null);
  const [deviations, setDeviations] = useState([]);
  const [deviationCounts, setDeviationCounts] = useState({ within: 0, outside: 0, total: 0 });
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filteredChangeRequests, setFilteredChangeRequests] = useState({ pending: [], approved: [], denied: [] });
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [currentEmployee, setCurrentEmployee] = useState();
  const [activeStartDate, setActiveStartDate] = useState(null);
  const [liveListEntries, setLiveListEntries] = useState([]);

  const token = localStorage.getItem('accessToken');
  const tokenCompany = localStorage.getItem('company');

  const navigate = useNavigate();

  useEffect(() => {
    if (viewMode === 'liveList') {
      const fetchLiveListEntries = async () => {
        try {
          const activeEmployees = await Promise.all(
            employeeList.map(async (employee) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Request-Headers': '*',
                },
                body: JSON.stringify({
                  token,
                  employeeId: employee.employeeId,
                }),
                redirect: 'follow',
              };
      
              const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimerDate', requestOptions);
              const result = await response.json();
      
              if (result.startDate && !result.endTime) {
                return {
                  ...employee,
                  activeStartDate: result.startDate,
                  location: result.location || null,
                };
              } else {
                return null;
              }
            })
          );
      
          const filteredActiveEmployees = activeEmployees.filter((emp) => emp !== null);
          setLiveListEntries(filteredActiveEmployees);
        } catch (error) {
          console.error('Error fetching live list entries:', error);
        }
      };
  
      fetchLiveListEntries();
    }
  }, [viewMode, employeeList]);

  useEffect(() => {
    const filteredDeviations = deviations.filter(hasNonZeroDuration);
  
    const within15Minutes = filteredDeviations.filter(d => Math.abs(d.deviation) <= 15).length;
    const outside15Minutes = filteredDeviations.filter(d => Math.abs(d.deviation) > 15).length;
  
    setDeviationCounts({
      within: within15Minutes,
      outside: outside15Minutes,
      total: filteredDeviations.length,
    });
  }, [deviations]);

  const fetchEmployeeData = async () => {
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token }),
      redirect: 'follow'
    };

    try {
      const response = await fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching employee data:', result.error);
      } else {
        console.log(result);
        setCurrentEmployee(result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getTimeDifference = (startTime, endTime, pause) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    let endHours, endMinutes;
  
    if (endTime) {
      [endHours, endMinutes] = endTime.split(':').map(Number);
    } else {
      const now = new Date();
      endHours = now.getHours();
      endMinutes = now.getMinutes();
    }
  
    const startDate = new Date();
    startDate.setHours(startHours, startMinutes, 0);
  
    const endDate = new Date();
    endDate.setHours(endHours, endMinutes, 0);
  
    let differenceInMilliseconds = endDate - startDate;
  
    if (differenceInMilliseconds < 0) {
      differenceInMilliseconds += 24 * 60 * 60 * 1000;
    }
  
    if (typeof pause === 'object') {
      pause = '00:00';
    }
  
    const pauseMilliseconds = pause ? parseInt(pause.split(':')[0]) * 3600000 + parseInt(pause.split(':')[1]) * 60000 : 0;
    differenceInMilliseconds -= pauseMilliseconds;
  
    const totalMinutes = Math.floor(differenceInMilliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  };

  const hasNonZeroDuration = (entry) => {
    const duration = getTimeDifference(
      entry?.startTime || entry?.newStartTime?.split('T')[1].substring(0, 5),
      entry?.endTime || entry?.newEndTime?.split('T')[1].substring(0, 5),
      entry?.pause
    );
    const [hours, minutes] = duration.split(':').map(Number);
    return !(hours === 0 && minutes === 0);
  };

  useEffect(() => {
    fetchEmployeeData()
  }, [])

  useEffect(() => {
      if (changeRequests.length > 0) {
          setFilteredChangeRequests({
              pending: changeRequests.filter(req => req.status === 'pending'),
              approved: changeRequests.filter(req => req.status === 'approved'),
              denied: changeRequests.filter(req => req.status === 'denied'),
          });
      }
  }, [changeRequests]);

  useEffect(() => {
      const pendingEntries = timeEntries.filter(entry => entry.status === 'pending');
      const approvedEntries = timeEntries.filter(entry => entry.status === 'approved');
      const deniedEntries = timeEntries.filter(entry => entry.status === 'denied');

      setFilteredChangeRequests({
          pending: pendingEntries,
          approved: approvedEntries,
          denied: deniedEntries,
      });
  }, [timeEntries]);

  const generateAllDaysOfYear = (year) => {
    const days = [];
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
  
    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      days.push(new Date(date));
    }
  
    return days;
  };

  const handleSaveAbsence = async (absenceData) => {
    const calculateDuration = (startTime, endTime) => {
      const [startHour, startMinute] = startTime.split(':').map(Number);
      const [endHour, endMinute] = endTime.split(':').map(Number);
      const start = new Date(0, 0, 0, startHour, startMinute);
      const end = new Date(0, 0, 0, endHour, endMinute);
      let duration = (end - start) / (1000 * 60 * 60);
      if (duration < 0) duration += 24;
      return duration;
    };
  
    const duration = calculateDuration(absenceData.startTime, absenceData.endTime);
  
    let pause = '00:00'; 
    if (duration >= 6 && duration <= 9) {
      pause = '00:30'; 
    } else if (duration > 9) {
      pause = '00:45'; 
    }
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        timeSlotData: {
          startTime: absenceData.startTime,
          endTime: absenceData.endTime,
          pause: pause,
          employeeId: selectedEmployee.employeeId,
          project: 'Abwesenheit',
          customer: '',
          activity: '',
          deviationComment: absenceData.reason,
          date: selectedDay,
        },
      }),
      redirect: 'follow'
    };
  
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addTime', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error adding time entry:', result.error);
      } else {
        const newEntry = {
          date: selectedDay,
          startTime: absenceData.startTime,
          endTime: absenceData.endTime,
          pause: pause,
          employeeId: selectedEmployee.employeeId,
          projectId: 'Abwesenheit',
          customerId: '',
          activityId: '',
          deviationComment: absenceData.reason,
          type: 'time',
          status: 'approved',
        };
  
        setAllTimeEntries((prevEntries) => [...prevEntries, newEntry]);
        if (selectedDay === newEntry.date) {
          setTimeEntries((prevEntries) => {
            const updatedEntries = [...prevEntries, newEntry];
            const totalHours = calculateTotalHours(updatedEntries);
            setTotalHoursForDay(totalHours);
            return updatedEntries;
          });
        }
  
        if (absenceData.reason === 'Urlaub') {
          const addHolidayOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Request-Headers': '*',
            },
            body: JSON.stringify({
              token,
              employeeId: selectedEmployee.employeeId,
              timeslot: {
                date: new Date(selectedDay).toISOString(),
                status: 'Urlaub'
              }
            }),
            redirect: 'follow'
          };

          try {
            const holidayResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addHoliday', addHolidayOptions);
            const holidayResult = await holidayResponse.json();
            if (holidayResult.error) {
              console.error('Error adding holiday:', holidayResult.error);
            } else {
              console.log('Holiday added successfully:', holidayResult);
              setHolidayPlan(prevPlan => ({
                ...prevPlan,
                timeslots: [...(prevPlan.timeslots || []), { date: selectedDay, status: 'Urlaub' }]
              }));
            }
          } catch (error) {
            console.error('Error adding holiday:', error);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    validateToken(token)
      .then(async isValid => {
        if (!isValid) {
          if (stayLoggedIn) {
          const refreshed = await refreshAccessToken();
          if (!refreshed) {
            setLoggedIn(false);
            navigate('/login');
          } else {
            const newToken = app.currentUser.accessToken;
            const isValidNewToken = await validateToken(newToken);
            if (!isValidNewToken) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              setLoggedIn(true);
            }
          }
        } else {
          setLoggedIn(false);
          navigate('/login');
        }
      } else {
          setLoggedIn(true);
        }
      })
      .catch(error => {
        console.error('Error validating token:', error);
        setLoggedIn(false);
        navigate('/login');
      });
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  function ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569 + 1);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }

  useEffect(() => {
    const fetchRoster = async () => {
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          targetEmployeeId: selectedEmployee?.employeeId
        }),
        redirect: 'follow'
      };

      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/downloadRoster', requestOptions);
        const result = await response.json();
        const convertedResult = result.schedule.map(item => ({
          ...item,
          Datum: ExcelDateToJSDate(item.Datum)
        }));
        setRosterData(convertedResult);
      } catch (error) {
        console.error('Error fetching roster:', error);
      }
    };

    fetchRoster();
  }, [token, selectedEmployee]);

  useEffect(() => {
    if (selectedEmployee) {
      checkActiveStartDate();
    }
  }, [selectedEmployee]);

  const checkActiveStartDate = async () => {
    const token = localStorage.getItem('accessToken');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        employeeId: selectedEmployee.employeeId,
      }),
      redirect: 'follow',
    };
  
    try {
      const response = await fetch(
        'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimerDate',
        requestOptions
      );
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching startDate:', result.error);
      } else if (result.startDate) {
        console.log('Employee has an active startDate:', result.startDate);
        setActiveStartDate(result.startDate);
      } else {
        console.log('Employee has no active startDate.');
        setActiveStartDate(null);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({ token, company: tokenCompany }),
        redirect: 'follow'
      };
  
      try {
        const response = await fetch(process.env.REACT_APP_EMPLOYEELIST_URL, requestOptions);
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching employee data:', result.error);
        } else {
          const filteredEmployees = result.filter(employee => 
            (currentEmployee.subEmployees?.includes(employee.employeeId) || 
            employee.employeeId === currentEmployee.employeeId)
          );
  
          const sortedEmployees = [...filteredEmployees].sort((a, b) => {
            const lastNameA = a.lastName.toUpperCase();
            const lastNameB = b.lastName.toUpperCase();
            if (lastNameA < lastNameB) {
              return -1;
            }
            if (lastNameA > lastNameB) {
              return 1;
            }
            return 0;
          });
  
          setEmployeeList(sortedEmployees);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchEmployees();
  }, [token, tokenCompany, currentEmployee]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getClients', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        setClients(result);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getProjects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        setProjects(result);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const fetchTasks = async () => {
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTasks', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        setTasks(result);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchClients();
    fetchProjects();
    fetchTasks();
  }, [token]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!selectedEmployee) return;
  
      setDeviationCounts({ within: 0, outside: 0, total: 0 });
      setDeviations([]);
      setMonths([]);
  
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          email: selectedEmployee.email
        }),
        redirect: 'follow'
      };
  
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching time entries:', result.error);
        } else {
          setTimeEntries(result);
          setAllTimeEntries(result);
  
          if (viewMode === 'month' || viewMode === 'year') {
            const uniqueMonths = Array.from(new Set(result.map(entry => entry.date.substring(0, 7))));
            setMonths(uniqueMonths);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
  };
  
    fetchInitialData();
  }, [selectedEmployee, token, viewMode]);

  useEffect(() => {
    if (viewMode === 'day') {
      const currentYear = new Date().getFullYear();
      const daysList = generateAllDaysOfYear(currentYear)
      .map(date => date.toISOString().split('T')[0])
      .reverse();
      setDays(daysList);
    }
  }, [viewMode]);

  const fetchTimeEntriesForMonth = async () => {
    if (!selectedEmployee || !selectedMonth || viewMode !== 'month') return;

    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
            token,
            email: selectedEmployee.email,
            month: selectedMonth
        }),
        redirect: 'follow'
    };

    try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
        const result = await response.json();
        if (result.error) {
            console.error('Error fetching time entries:', result.error);
        } else {
            setTimeEntries(result);
            setAllTimeEntries(result);

            const pendingEntries = result.filter(entry => entry.status === 'pending');

            const calculateDeviations = (entries) => entries.map(entry => {
                const [startHours, startMinutes] = entry.startTime.split(':').map(Number);
                const [endHours, endMinutes] = entry.endTime.split(':').map(Number);
                const start = new Date(entry.date);
                const end = new Date(entry.date);
                start.setHours(startHours, startMinutes, 0);
                end.setHours(endHours, endMinutes, 0);
                let durationMinutes = (end - start) / 60000;

                const dayOfWeek = new Date(entry.date).getDay();
                const dayName = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][dayOfWeek];
                const targetMinutes = annualPlan[dayName] * 60;

                return {
                    ...entry,
                    deviation: durationMinutes - targetMinutes,
                    actualMinutes: durationMinutes,
                    targetMinutes: targetMinutes,
                    type: "time"
                };
            });
            const deviations = [
                ...calculateDeviations(pendingEntries),
            ];

            setDeviations(deviations);

            const within15Minutes = deviations.filter(d => Math.abs(d.deviation) <= 15);
            const outside15Minutes = deviations.filter(d => Math.abs(d.deviation) > 15);

            setDeviationCounts({
                within: deviations.filter(d => Math.abs(d.deviation) <= 15).length,
                outside: deviations.filter(d => Math.abs(d.deviation) > 15).length,
                total: deviations.length
            });
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
  
  useEffect(() => {
    fetchTimeEntriesForMonth();
  }, [selectedEmployee, selectedMonth, token, viewMode]);

  useEffect(() => {
    if (viewMode === 'year') {
      const uniqueMonths = Array.from(new Set(allTimeEntries.map(entry => entry.date.substring(0, 7))));
      setMonths(uniqueMonths);
    } else if (viewMode === 'day') {
      const uniqueDays = Array.from(new Set(allTimeEntries.map(entry => entry.date))).sort();
    }
  }, [viewMode, allTimeEntries]);

  useEffect(() => {
    console.log(rosterData);
    const filtered = rosterData.filter((entry, i) => {
      if (i === 0) return false;
      console.log(i);
      
      const entryDate = new Date(entry.Datum);
      
      const year = entryDate.getFullYear();
      const month = String(entryDate.getMonth() + 1).padStart(2, '0');
      const day = String(entryDate.getDate()).padStart(2, '0');
      
      const formattedEntryDate = `${year}-${month}-${day}`;
  
      console.log(entryDate);
      console.log(selectedDay);
      console.log(formattedEntryDate);
  
      return formattedEntryDate === selectedDay;
    });
    console.log(filtered);
    setFilteredRosterData(filtered);
  }, [rosterData, selectedDay]);

  const handleCheckboxChange = (entry) => {
    const entryId = entry.type === 'time' ? entry.id : entry._id;
  
    setSelectedEntries(prev => {
      if (prev.some(e => (e.type === 'time' ? e.id : e._id) === entryId)) {
        return prev.filter(e => (e.type === 'time' ? e.id : e._id) !== entryId);
      } else {
        return [...prev, entry];
      }
    });
  };

  useEffect(() => {
    if (viewMode === 'year') {
      let totalTargetHours = 0;
      let totalRecordedMinutes = 0;
      let totalDiffHours = 0;
  
      months.forEach(month => {
        const [currentYear, currentMonthNum] = month.split('-').map(Number);
        const monthlyTargetHours = annualPlan.monday ? calculateMonthlyHours(currentYear, currentMonthNum) : selectedEmployee?.hours_month;
        totalTargetHours += monthlyTargetHours;
  
        const monthlyTimes = getMonthlyTimes(month);
        const totalHours = calculateTotalHours(monthlyTimes);
        totalRecordedMinutes += totalHours.totalMinutes;
  
        const missedHoursInside = calculateMissedHours(month);
        const diffHours = (totalHours.totalMinutes / 60 + missedHoursInside) - monthlyTargetHours;
        totalDiffHours += diffHours;
      });
  
      const totalRecordedHours = Math.floor(totalRecordedMinutes / 60);
      totalRecordedMinutes = totalRecordedMinutes % 60;
  
      setAnnualOverview({
        totalTargetHours,
        totalRecordedHours,
        totalRecordedMinutes,
        totalDiffHours,
      });
    }
  }, [viewMode, months, allTimeEntries, annualPlan, selectedEmployee]);

  const handleEmployeeChange = (e) => {
    const selectedEmail = e.target.value;
    const employee = employeeList.find(emp => emp.email === selectedEmail);
    setSelectedEmployee(employee);
    setTimeEntries([]);
    setAllTimeEntries([]);
    setSelectedMonth('');
    setSelectedDay('');
    if (viewMode !== 'month') {
      setViewMode('month');
    }
  };

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    setSelectedMonth(selectedMonth);
  
    const [currentYear, currentMonthNum] = selectedMonth.split('-').map(Number);
    const targetHours = annualPlan.monday ? calculateMonthlyHours(currentYear, currentMonthNum) : selectedEmployee?.hours_month;
    setMonthlyTargetHours(Math.floor(targetHours));
  
    const fetchChangeRequests = async () => {
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          month: selectedMonth,
          requesterEmployeeId: selectedEmployee.employeeId
        }),
        redirect: 'follow'
      };
  
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getChangeRequests', requestOptions);
        const result = await response.json();

        const changeRequestsEntries = result.pending.filter(req => req.status === 'pending');

        const calculateChangeRequestDeviations = (entries) => entries.map(entry => {
          const newStart = new Date(entry.newStartTime);
          const newEnd = new Date(entry.newEndTime);
          const durationMinutes = (newEnd - newStart) / 60000;
  
          const dayOfWeek = new Date(entry.newStartTime).getDay();
          const dayName = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][dayOfWeek];
          const targetMinutes = annualPlan[dayName] * 60;
  
          return {
            ...entry,
            deviation: durationMinutes - targetMinutes,
            actualMinutes: durationMinutes,
            targetMinutes: targetMinutes,
            type: "change request"
          };
        });

        const deviations = [
          ...calculateChangeRequestDeviations(changeRequestsEntries)
        ];

        setDeviations(prevDeviations => [...prevDeviations, ...deviations]);

        setDeviationCounts({
          within: deviations.filter(d => Math.abs(d.deviation) <= 15).length,
          outside: deviations.filter(d => Math.abs(d.deviation) > 15).length,
          total: deviations.length
        });

        if (result.error) {
          console.error('Error fetching change requests:', result.error);
        } else {
          setChangeRequests(result);
          setAllChangeRequests(result);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchChangeRequests();
  };

  const handleDayChange = async (e) => {
    const selectedDate = e.target.value;
    setSelectedDay(selectedDate);
    setViewMode('day');
  
    const fetchChangeRequestsForDay = async () => {
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          requesterEmployeeId: selectedEmployee.employeeId
        }),
        redirect: 'follow'
      };
  
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getChangeRequests', requestOptions);
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching change requests:', result.error);
        } else {
          setAllChangeRequests(result);
          setChangeRequests({
            pending: result.pending.filter(req => req.date === selectedDate || req.newStartTime.startsWith(selectedDate)),
            approved: result.approved.filter(req => req.date === selectedDate || req.newStartTime.startsWith(selectedDate)),
            denied: result.denied.filter(req => req.date === selectedDate || req.newStartTime.startsWith(selectedDate)),
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchChangeRequestsForDay();
    setTimeEntries(allTimeEntries.filter(entry => entry.date === selectedDate));
    const dayEntries = getFilteredTimeEntriesForDay(selectedDate);
    const totalHours = calculateTotalHours(dayEntries);
    setTotalHoursForDay(totalHours);
  };

  useEffect(() => {
    if (selectedDay && viewMode === 'day') {
      const dayEntries = getFilteredTimeEntriesForDay(selectedDay);
      const dayChangeRequests = getFilteredChangeRequestsForDay(selectedDay);
      setTimeEntries(dayEntries);
      setChangeRequests(dayChangeRequests);
    }
  }, [selectedDay, viewMode, allTimeEntries, allChangeRequests]);

  useEffect(() => {
    if (selectedDay && activeStartDate) {
      const startDate = new Date(activeStartDate);
      const selectedDate = new Date(selectedDay);
  
      const sameDate =
        startDate.getFullYear() === selectedDate.getFullYear() &&
        startDate.getMonth() === selectedDate.getMonth() &&
        startDate.getDate() === selectedDate.getDate();
  
      if (sameDate) {
        console.log('Active startDate is on the selected day.');
      } else {
        console.log('Active startDate is NOT on the selected day.');
      }
    }
  }, [selectedDay, activeStartDate]);

  const getFilteredTimeEntriesForDay = (selectedDay) => {
    return allTimeEntries.filter(entry => entry.date === selectedDay);
  };

  const getFilteredChangeRequestsForDay = (selectedDay) => {
    const filteredRequests = {
      pending: allChangeRequests.pending.filter(req => req.date === selectedDay || req.newStartTime.startsWith(selectedDay)),
      approved: allChangeRequests.approved.filter(req => req.date === selectedDay || req.newStartTime.startsWith(selectedDay)),
      denied: allChangeRequests.denied.filter(req => req.date === selectedDay || req.newStartTime.startsWith(selectedDay))
    };
    return filteredRequests;
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    setViewMode('month');
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setSelectedRequest(null);
  };

  // const handleRequestClick = (request) => {
  //   console.log(annualPlan)
  //   const requestDate = new Date(request.date || request.newStartTime).toISOString().split('T')[0];
  
  //   const isValidDate = (d) => {
  //     return d instanceof Date && !isNaN(d);
  //   };

  //   console.log(request)
  
  //   const rosterEntry = rosterData.find(item => {
  //     const date = new Date(item.Datum);
  //     return isValidDate(date) && date.toISOString().split('T')[0] === requestDate;
  //   });
  
  //   let deviationMessage = '';
  
  //   if (rosterEntry) {
  //     let requestStartTime = new Date(`${requestDate}T${request.startTime}`);
  //     let requestEndTime = new Date(`${requestDate}T${request.endTime}`);
  //     if (request.type === "change request") {
  //       requestStartTime = new Date(request.newStartTime);
  //       requestEndTime = new Date(request.newEndTime);
  //     }
  //     const rosterEntryStartTime = new Date(`${requestDate}T${rosterEntry.Start}`);
  //     const rosterEntryEndTime = new Date(`${requestDate}T${rosterEntry.Ende}`);
  //     const rosterEntryBreak = rosterEntry["Pausen (min)"] || 0;
  
  //     const startDifference = Math.abs((requestStartTime - rosterEntryStartTime) / 60000);
  //     const endDifference = Math.abs((requestEndTime - rosterEntryEndTime) / 60000);
  
  //     const totalDifferenceMinutes = startDifference + endDifference - rosterEntryBreak;
  
  //     const hours = Math.floor(totalDifferenceMinutes / 60);
  //     const minutes = totalDifferenceMinutes % 60;
  
  //     deviationMessage = `Abweichungen vom Dienstplan: ${hours}:${minutes.toString().padStart(2, '0')}`;
  //   }
  
  //   setSelectedRequest({
  //     ...request,
  //     deviationMessage,
  //   });
  // };

  const handleRequestClick = (request) => {
    if (request.type == "time") {
      const requestDate = new Date(request.date || request.newStartTime).toISOString().split('T')[0];
    
      const getWeekday = (date) => {
        const dayIndex = new Date(date).getDay();
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return daysOfWeek[dayIndex];
      };
    
      const dayOfWeek = getWeekday(requestDate);
    
      const targetHours = annualPlan[dayOfWeek] || 0;
      const targetMinutes = targetHours * 60;
    
      const [startHours, startMinutes] = request.startTime.split(':').map(Number);
      const [endHours, endMinutes] = request.endTime.split(':').map(Number);
    
      const start = new Date(requestDate);
      const end = new Date(requestDate);
      start.setHours(startHours, startMinutes, 0);
      end.setHours(endHours, endMinutes, 0);
    
      let durationMinutes = (end - start) / 60000;
      const pauseMinutes = request.pause ? parseInt(request.pause.split(':')[0]) * 60 + parseInt(request.pause.split(':')[1]) : 0;
      durationMinutes -= pauseMinutes;
    
      const deviationMinutes = durationMinutes - targetMinutes;
      const deviationHours = Math.floor(Math.abs(deviationMinutes) / 60);
      const deviationMins = Math.abs(deviationMinutes) % 60;
    
      const deviationMessage = `Abweichungen vom Ziel: ${deviationHours}${deviationMins > 0 ? `:${deviationMins}` : ':00'} Stunden`;
    
      setSelectedRequest({
        ...request,
        deviationMessage,
      });
    }
    if (request.type == "change request") {
      setSelectedRequest({
        ...request,
      });
    }
  };

  const getMonthlyTimes = (month) => {
    return allTimeEntries.filter(entry => entry.date.startsWith(month));
  };

  const calculateTotalHours = (entries) => {
    let totalMinutes = 0;
  
    if (!entries || entries.length === 0) {
      return { hours: 0, minutes: 0, totalMinutes: 0 };
    }
  
    entries.forEach(entry => {
      if (entry.status === 'approved' || !entry.status) {
        const [startHours, startMinutes] = entry.startTime.split(':').map(Number);
        const [endHours, endMinutes] = entry.endTime.split(':').map(Number);
        const start = new Date(entry.date);
        const end = new Date(entry.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        let durationMinutes = (end - start) / 60000;
  
        if (typeof entry.pause == 'string') {
          const [pauseHours, pauseMinutes] = entry.pause.split(':').map(Number);
          const pauseMinutesTotal = (pauseHours * 60) + pauseMinutes;
          durationMinutes -= pauseMinutesTotal;
        }
  
        totalMinutes += durationMinutes;
      }
    });
  
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes, totalMinutes };
  };

  const monthlyTimes = getMonthlyTimes(selectedMonth);
  const totalHours = calculateTotalHours(monthlyTimes);

  const categorizedEntries = {
    pending: [],
    approved: [],
    denied: []
  };

  timeEntries.forEach(entry => {
    const categorizedEntry = {
      ...entry,
      type: 'time'
    };

    switch (entry.status) {
      case 'pending':
        if (hasNonZeroDuration(entry)) {
          categorizedEntries.pending.push(categorizedEntry);
        }
        break;
      case 'approved':
        categorizedEntries.approved.push(categorizedEntry);
        break;
      case 'denied':
        categorizedEntries.denied.push(categorizedEntry);
        break;
      default:
        categorizedEntries.approved.push(categorizedEntry);
        break;
    }
  });

  changeRequests?.pending?.forEach(request => {
    const categorizedRequest = {
      ...request,
      type: 'change request'
    };
    if (hasNonZeroDuration(request)) {
      categorizedEntries.pending.push(categorizedRequest);
    }
  });

  changeRequests?.approved?.forEach(request => {
    const categorizedRequest = {
      ...request,
      type: 'change request'
    };
    categorizedEntries.approved.push(categorizedRequest);
  });

  changeRequests?.denied?.forEach(request => {
    const categorizedRequest = {
      ...request,
      type: 'change request'
    };
    categorizedEntries.denied.push(categorizedRequest);
  });

  const renderLiveList = () => (
    <div className="live-list-section bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-3xl font-bold mb-6 text-blue-700">Live-Liste - Mitarbeiter online</h3>
      {liveListEntries.length > 0 ? (
        <ul className="space-y-4">
          {liveListEntries.map((employee) => {
            const startDate = new Date(employee.activeStartDate);
            const formattedStartDate = startDate.toLocaleDateString('de-DE', {
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            });
            
            return (
              <li key={employee.employeeId} className="flex items-center">
                <div className="text-lg font-semibold text-gray-900 mr-2">
                  {`${employee.firstName} ${employee.lastName}`}
                </div>
                <span className="text-gray-600 text-sm ml-2">
                  {formattedStartDate}
                  {employee.location && `, ${employee.location}`}
                </span>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-gray-600 text-lg">Derzeit sind keine Mitarbeiter online.</p>
      )}
    </div>
  );

  const renderMonthlyEntriesTable = (entries) => (
    <div className='h-[650px] overflow-auto'>
      <table className="w-full rounded-lg">
        <thead>
          <tr className="bg-[#FCFCFC]" style={{ "borderBottom": "1px solid #EFEFF0" }}>
            <th className="p-2 pl-6"></th>
            <th className="pl-[32px] text-left text-[#7B97FB]">Datum</th>
            <th className="p-2 text-left text-[#7B97FB]">Dauer</th>
            <th className="p-2 text-left text-[#7B97FB]">Kunde</th>
            <th className="p-2 text-left text-[#7B97FB]">Projekt</th>
            <th className="p-2 text-left text-[#7B97FB]">Tätigkeit</th>
            <th className="p-2 text-left text-[#7B97FB]">Typ</th>
            <th className="p-2 text-left text-[#7B97FB]">Status</th>
          </tr>
        </thead>
        <tbody className="bg-[#FCFCFC]">
          {entries.map(entry => {
            const projectName = entry.type === "change request"
              ? projects.find(project => project._id === entry.project)?.name || entry.project
              : projects.find(project => project._id === entry.projectId)?.name || entry.projectId;
            const taskName = entry.type === "change request"
              ? tasks.find(task => task._id === entry.task)?.name || entry.task
              : tasks.find(task => task._id === entry.taskId)?.name || entry.taskId;
            const customerName = entry.type === "change request"
              ? clients.find(client => client._id === entry.customer)?.company.name || entry.customer
              : clients.find(client => client._id === entry.customerId)?.company.name || entry.customerId;
  
            return (
              <tr key={entry._id || entry.id} className="cursor-pointer">
                <td className="p-2 pl-6">
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(entry)}
                    checked={selectedEntries.some(e => e.id === entry.id || e._id === entry._id)}
                  />
                </td>
                <td className="pl-[32px] font-bold">{new Date(entry.date || entry.newStartTime).toLocaleDateString("de-DE")}</td>
                <td className={`p-2 font-bold`}>{getTimeDifference(entry?.startTime || entry?.newStartTime?.split('T')[1].substring(0, 5), entry?.endTime || entry?.newEndTime?.split('T')[1].substring(0, 5), entry?.pause)}</td>
                <td className="p-2">{customerName}</td>
                <td className="p-2">{projectName === "Abwesenheit" ? "" : projectName}</td>
                <td className="p-2">{taskName}</td>
                <td className="p-2">{projectName === "Abwesenheit" ? "Abwesenheit" : entry.type === "time" ? "Zeit" : "Änderungsanfrage"}</td>
                <td className="p-2">{entry.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };
  
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const renderEntriesTable = (entries) => (
    <div className='h-[650px] overflow-auto'>
      <table className="w-full rounded-lg">
        <thead>
          <tr style={{ backgroundColor: 'white', borderBottom: '1px solid #EFEFF0' }}>
            <th className="p-2 pl-6"></th>
            {viewMode === 'day' ? (
              <>
                <th className="p-2 pl-6 text-left" style={{ color: '#7B97FB' }}>Dauer</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Projekt</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Typ</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Status</th>
              </>
            ) : (
              <>
                <th className="pl-[32px] text-left" style={{ color: '#7B97FB' }}>Datum</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Dauer</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Kunde</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Projekt</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Tätigkeit</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Typ</th>
                <th className="p-2 text-left" style={{ color: '#7B97FB' }}>Status</th>
              </>
            )}
          </tr>
        </thead>
        <tbody style={{ backgroundColor: 'white', borderRadius: '8px' }}>
          {entries
            .filter(entry => {
              const duration = getTimeDifference(entry?.startTime || entry?.newStartTime?.split('T')[1].substring(0, 5), entry?.endTime || entry?.newEndTime?.split('T')[1].substring(0, 5), entry?.pause);
              const [hours, minutes] = duration.split(':').map(Number);
              return !(hours === 0 && minutes === 0);
            })
            .map((entry, index) => {
              const projectName = entry.type === "change request"
                ? (projects.find(project => project._id === entry.project) && Object.keys(projects.find(project => project._id === entry.project)).length ? projects.find(project => project._id === entry.project).name : '')
                : (projects.find(project => project._id === entry.projectId) && Object.keys(projects.find(project => project._id === entry.projectId)).length ? projects.find(project => project._id === entry.projectId).name : '');
              
              const taskName = entry.type === "change request"
                ? (tasks.find(task => task._id === entry.task) && Object.keys(tasks.find(task => task._id === entry.task)).length ? tasks.find(task => task._id === entry.task).name : '')
                : (tasks.find(task => task._id === entry.taskId) && Object.keys(tasks.find(task => task._id === entry.taskId)).length ? tasks.find(task => task._id === entry.taskId).name : '');
              
              const customerName = entry.type === "change request"
                ? (clients.find(client => client._id === entry.customer) && Object.keys(clients.find(client => client._id === entry.customer)).length ? clients.find(client => client._id === entry.customer).company.name : '')
                : (clients.find(client => client._id === entry.customerId) && Object.keys(clients.find(client => client._id === entry.customerId)).length ? clients.find(client => client._id === entry.customerId).company.name : '');
  
              return (
                <tr 
                  key={entry._id} 
                  className="cursor-pointer"
                  onClick={() => handleRequestClick(entry)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={hoveredRowIndex === index ? { backgroundColor: '#f0f0f0' } : { backgroundColor: 'inherit' }}
                >
                  <td className="p-2 pl-6">
                    {viewMode !== 'day' && (
                      entry.type === 'time' ?
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(entry)}
                          checked={selectedEntries.some(e => e.id === entry.id)}
                        />
                        :
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(entry)}
                          checked={selectedEntries.some(e => e._id === entry._id)}
                        />
                    )}
                  </td>
                  {viewMode !== 'day' && (
                    <td className="pl-[32px] font-bold">{new Date(entry.date || entry.newStartTime).toLocaleDateString("de-DE")}</td>
                  )}
                  <td className={`p-2 ${viewMode === 'day' && "pl-6"} font-bold`}>
                    {getTimeDifference(entry?.startTime || entry?.newStartTime?.split('T')[1].substring(0, 5), entry?.endTime || entry?.newEndTime?.split('T')[1].substring(0, 5), entry?.pause)}
                  </td>
                  {viewMode !== 'day' && (
                    <>
                      <td className="p-2">{customerName}</td>
                      <td className="p-2">{projectName === "Abwesenheit" ? "" : projectName}</td>
                      <td className="p-2">{taskName}</td>
                      <td className="p-2">{projectName === "Abwesenheit" ? "Abwesenheit" : entry.type === "time" ? "Zeit" : "Änderungsanfrage"}</td>
                      <td className="p-2">{entry.status === "pending" ? 'Ausstehend' : entry.status === 'approved' ? 'Bestätigt' : entry.status === 'denied' ? 'Abgelehnt' : ''}</td>
                    </>
                  )}
                  {viewMode === 'day' && (
                    <>
                      <td className="p-2">{projectName}</td>
                      <td className="p-2">{entry.type === "time" ? "Zeit" : "Änderungsanfrage"}</td>
                      <td className="p-2 pr-8">{entry.status === "pending" ? 'Ausstehend' : entry.status === 'approved' ? 'Bestätigt' : entry.status === 'denied' ? 'Abgelehnt' : ''}</td>
                    </>
                  )}
                </tr>
              );
            })}
          {viewMode === 'day' && (
            <tr>
              <td colSpan={1}></td> 
              <td colSpan={5} className="p-2">
                <button className="text-[#0000FF] py-2 px-4 rounded-lg font-medium text-[14px] leading-[20px]" onClick={() => setIsAbsenceDialogOpen(true)}>
                  Abwesenheit Hinzufügen
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const handleEditTime = async (data) => {
    console.log(data)
    const editTimeOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        employeeId: selectedEmployee.employeeId,
        timeSlotData: {
          id: selectedRequest.id,
          date: data.date,
          startTime: data.startTime,
          endTime: data.endTime,
          status: selectedRequest.status,
          pause: data.pause
        }
      }),
      redirect: 'follow'
    };
  
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTime', editTimeOptions);
      const result = await response.json();
  
      if (result.error) {
        console.error('Error changing time:', result.error);
      } else {
        const updatedEntries = allTimeEntries.map(entry => {
          if (entry.id === selectedRequest.id) {
            return { ...entry, date: data.date, startTime: data.startTime, endTime: data.endTime, status: selectedRequest.status, pause: data.pause };
          }
          return entry;
        });
        setAllTimeEntries(updatedEntries);
  
        const updatedDeviations = deviations.map(entry => {
          if (entry.id === selectedRequest.id) {
            return { ...entry, date: data.date, startTime: data.startTime, endTime: data.endTime, status: selectedRequest.status, pause: data.pause };
          }
          return entry;
        });
        setDeviations(updatedDeviations);
  
        let filteredEntries = updatedEntries;
        if (viewMode === 'month' && selectedMonth) {
          filteredEntries = updatedEntries.filter(entry => entry.date.startsWith(selectedMonth));
        } else if (viewMode === 'day' && selectedDay) {
          filteredEntries = updatedEntries.filter(entry => entry.date === selectedDay);
        }
        setTimeEntries(filteredEntries);
  
        setSelectedRequest(null);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderRosterTable = (entries) => {
    let activeEntry = null;
  
    if (activeStartDate && viewMode === 'day' && selectedDay) {
      const startDate = new Date(activeStartDate);
      const selectedDate = new Date(selectedDay);
  
      const sameDate =
        startDate.getFullYear() === selectedDate.getFullYear() &&
        startDate.getMonth() === selectedDate.getMonth() &&
        startDate.getDate() === selectedDate.getDate();
  
      if (sameDate) {
        const now = new Date();
        const duration = getTimeDifference(
          startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0'),
          now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0')
        );
  
        activeEntry = {
          id: 'active',
          duration: duration,
          Standort: 'Zentrale',
        };
      }
    }
  
    const allEntries = activeEntry ? [activeEntry, ...entries] : entries;
  
    return (
      <div style={{ height: '650px', overflow: 'auto' }}>
        <table style={{ width: '100%', borderRadius: '0.5rem' }}>
          <thead>
            <tr style={{ backgroundColor: 'white', borderBottom: '1px solid #EFEFF0' }}>
              <th
                style={{ padding: '0.5rem', paddingLeft: '1.5rem', textAlign: 'left', color: '#7B97FB' }}
              >
                Dauer
              </th>
              <th style={{ padding: '0.5rem', textAlign: 'left', color: '#7B97FB' }}>Standort</th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: 'white' }}>
            {allEntries.map((entry, index) => {
              const duration = entry.duration || getTimeDifference(entry.Start, entry.Ende);
              return (
                <tr key={entry.id || index}>
                  <td style={{ padding: '0.5rem', paddingLeft: '1.5rem', fontWeight: 'bold' }}>{duration}</td>
                  <td style={{ padding: '0.5rem' }}>{entry.locationName || ''}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const updateChangeRequests = (updatedRequest, newStatus) => {
    setChangeRequests(prev => ({
      ...prev,
      pending: prev.pending.filter(req => req._id !== updatedRequest._id),
      approved: newStatus === 'approved' ? [...prev.approved, updatedRequest] : prev.approved,
      denied: newStatus === 'denied' ? [...prev.denied, updatedRequest] : prev.denied
    }));
  };

  const handleApprove = async (isChecked, selectedReason, letPass, isBetrieblichChecked) => {
    if (!selectedRequest) return;
  
    console.log(letPass);
    console.log(selectedRequest);
    console.log(selectedRequest.deviationMessage);
  
    if (selectedRequest?.deviationMessage && !letPass) {
      const totalDeviationMinutes = parseFloat(selectedRequest.deviationMessage?.split(' ')[3].split(':').reduce((acc, time) => (60 * acc) + +time));
      console.log(totalDeviationMinutes);
      if (totalDeviationMinutes > 15) {
        setIsWarningDialogOpen(true);
        return;
      }
    }
  
    if (selectedRequest.type === 'change request') {
      const changeRequestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          changeRequestId: selectedRequest._id,
          status: 'approved',
          reason: selectedReason || undefined,
          betrieblicheAnordnung: isBetrieblichChecked || undefined,
        }),
        redirect: 'follow'
      };
    
      const changeTimeOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          employeeId: selectedRequest.employeeId,
          timeSlotData: {
            id: selectedRequest.timeId,
            date: selectedRequest.newStartTime.split('T')[0],
            startTime: selectedRequest.newStartTime.split('T')[1].substring(0, 5),
            endTime: selectedRequest.newEndTime.split('T')[1].substring(0, 5),
            status: 'approved',
            selectedReason: selectedReason || undefined,
            isBetrieblichChecked: isBetrieblichChecked || undefined,
          }
        }),
        redirect: 'follow'
      };
    
      try {
        const changeRequestResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/editChangeRequest', changeRequestOptions);
        const changeRequestResult = await changeRequestResponse.json();
  
        if (changeRequestResult.error) {
          console.error('Error approving change request:', changeRequestResult.error);
        } else {
          const updatedChangeRequests = {
            ...changeRequests,
            pending: changeRequests.pending.filter(req => req.timeId !== selectedRequest.timeId),
            approved: [...changeRequests.approved, { ...selectedRequest, status: 'approved' }],
          };
          setChangeRequests(updatedChangeRequests);
  
          const changeTimeResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTime', changeTimeOptions);
          const changeTimeResult = await changeTimeResponse.json();
  
          if (changeTimeResult.error) {
            console.error('Error changing time:', changeTimeResult.error);
          } else {
            const otherRequests = changeRequests.pending.filter(req => req.timeId === selectedRequest.timeId && req._id !== selectedRequest._id);
  
            await Promise.all(
              otherRequests.map(async (request) => {
                const declineOptions = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    token,
                    changeRequestId: request._id,
                    status: 'declined',
                  }),
                  redirect: 'follow'
                };
                await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/editChangeRequest', declineOptions);
              })
            );
  
            const updatedDeviations = deviations.filter(entry => entry._id !== selectedRequest._id);
            setDeviations(updatedDeviations);
  
            const within15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) <= 15);
            const outside15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) > 15);
            setDeviationCounts({
              within: within15Minutes.length,
              outside: outside15Minutes.length,
              total: within15Minutes.length + outside15Minutes.length
            });
  
            const updatedEntries = allTimeEntries.map(entry => {
              if (entry.id === selectedRequest.timeId) {
                return { ...entry, startTime: selectedRequest.newStartTime.split('T')[1].substring(0, 5), endTime: selectedRequest.newEndTime.split('T')[1].substring(0, 5), status: 'approved' };
              }
              return entry;
            });
            setAllTimeEntries(updatedEntries);
            setTimeEntries(updatedEntries.filter(entry => entry.date.startsWith(selectedMonth)));
  
            setSelectedRequest(null);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  
    if (selectedRequest.type === 'time') {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            employeeId: selectedRequest.employeeId || selectedEmployee.employeeId,
            timeSlotData: {
              id: selectedRequest.id,
              date: selectedRequest.date,
              startTime: selectedRequest.startTime,
              endTime: selectedRequest.endTime,
              status: 'approved',
              selectedReason: selectedReason || undefined,
              isBetrieblichChecked: isBetrieblichChecked || undefined,
            }
          }),
          redirect: 'follow'
        };
  
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTimeStatus', requestOptions);
        const result = await response.json();
  
        if (result.error) {
          console.error('Error changing time status:', result.error);
        } else {
          const updatedAllEntries = allTimeEntries.map(entry => {
            if (entry.id === selectedRequest.id) {
              return { ...entry, status: 'approved', type: selectedRequest.type };
            }
            return entry;
          });
          setAllTimeEntries(updatedAllEntries);
  
          const updatedPendingEntries = timeEntries.filter(entry => entry.id !== selectedRequest.id);
          setTimeEntries(updatedPendingEntries);
  
          const updatedDeviations = deviations.filter(entry => entry.id !== selectedRequest.id);
          setDeviations(updatedDeviations);
  
          const within15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) <= 15).length;
          const outside15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) > 15).length;
          setDeviationCounts({
            within: within15Minutes,
            outside: outside15Minutes,
            total: within15Minutes + outside15Minutes
          });
  
          setChangeRequests(prev => ({
            ...prev,
            approved: [...prev.approved, { ...selectedRequest, status: 'approved' }],
            pending: prev.pending.filter(req => req.id !== selectedRequest.id)
          }));
  
          setSelectedRequest(null);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  
  const handleSaveWarningReason = (isChecked, selectedReason, isBetrieblichChecked) => {
    handleApprove(isChecked, selectedReason, true, isBetrieblichChecked);
  };

  const calculateMissedHours = (currentMonth) => {
    const importantDates = holidayPlan?.timeslots?.filter(day => day.status !== 'Wochenende');
    console.log(importantDates)
    const [currentYear, currentMonthNum] = currentMonth.split('-').map(Number);
    let missedHours = 0;
  
    importantDates?.forEach(item => {
      const date = new Date(item.date);
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth();
      const day = date.getUTCDay() + 1;
  
      if (year === currentYear && month === currentMonthNum - 1) {
        let weekday;
        switch (day) {
          case 0:
            weekday = 'sunday';
            break;
          case 1:
            weekday = 'monday';
            break;
          case 2:
            weekday = 'tuesday';
            break;
          case 3:
            weekday = 'wednesday';
            break;
          case 4:
            weekday = 'thursday';
            break;
          case 5:
            weekday = 'friday';
            break;
          case 6:
            weekday = 'saturday';
            break;
          default:
            weekday = null;
        }
  
        if (weekday && weekday !== 'saturday' && weekday !== 'sunday') {
          missedHours += annualPlan[weekday];
        }
      }
    });

    return missedHours;
  };

  useEffect(() => {
    const missedHoursResult = calculateMissedHours(selectedMonth);
    setMissedHours(missedHoursResult);
  }, [annualPlan, holidayPlan, selectedMonth]);

  useEffect(() => {
    const fetchAnnualPlan = async () => {
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          employeeId: selectedEmployee?.employeeId
        }),
        redirect: 'follow',
      };

      try {
        const response = await fetch(
          'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getSpecificAnnualPlan',
          requestOptions
        );
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching annual plan:', result.error);
        } else {
          setAnnualPlan(result.annualPlan);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const fetchHolidayPlan = async () => {
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          employeeId: selectedEmployee?.employeeId
        }),
        redirect: 'follow',
      };

      try {
        const response = await fetch(
          'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getSpecificHolidayPlan',
          requestOptions
        );
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching holiday plan:', result.error);
        } else {
          setHolidayPlan(result.holidayPlan);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAnnualPlan();
    fetchHolidayPlan();
  }, [selectedEmployee]);

  const handleReject = () => {
    if (!selectedRequest) return;
  
    setIsRejectionDialogOpen(true);
  };

  const handleViewChange = (e) => {
    const newViewMode = e.target.value;
    setViewMode(newViewMode);
    
    if (newViewMode === 'day') {
      setSelectedMonth('');
      setSelectedDay('');
      const uniqueDays = Array.from(new Set(allTimeEntries.map(entry => entry.date))).sort();
    } else if (newViewMode === 'month' || newViewMode === 'year') {
      setSelectedDay('');
      const uniqueMonths = Array.from(new Set(allTimeEntries.map(entry => entry.date.substring(0, 7))));
      setMonths(uniqueMonths);
      setSelectedMonth('');
    }
  };

  const getSortedMonths = () => {
    const currentMonth = new Date().toISOString().substring(0, 7);

    const currentMonthIndex = months.indexOf(currentMonth);

    return currentMonthIndex !== -1
      ? [...months.slice(currentMonthIndex), ...months.slice(0, currentMonthIndex)]
      : months;
  };

  const handleSaveRejectionReason = async (reason) => {
    setRejectionReason(reason);
    setIsRejectionDialogOpen(false);
  
    if (!selectedRequest) return;
  
    if (selectedRequest.type === 'change request') {
      try {
        const changeRequestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            changeRequestId: selectedRequest._id,
            status: 'denied',
            comment: reason,
            leadResponse: true
          }),
          redirect: 'follow'
        };
  
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/editChangeRequest', changeRequestOptions);
        const result = await response.json();
  
        if (result.error) {
          console.error('Error rejecting change request:', result.error);
        } else {
          const updatedChangeRequests = {
            ...changeRequests,
            pending: changeRequests.pending.filter(req => req._id !== selectedRequest._id),
            denied: [...changeRequests.denied, { ...selectedRequest, status: 'denied', comment: reason }],
          };
          setChangeRequests(updatedChangeRequests);
          setSelectedRequest(null);

          const updatedDeviations = deviations.filter(entry => entry._id !== selectedRequest._id)
          setDeviations(updatedDeviations);

          const within15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) <= 15);
          const outside15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) > 15);
          setDeviationCounts({
            within: within15Minutes.length,
            outside: outside15Minutes.length,
            total: within15Minutes.length + outside15Minutes.length
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  
    if (selectedRequest.type === 'time') {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            employeeId: selectedRequest.employeeId || selectedEmployee.employeeId,
            timeSlotData: {
              id: selectedRequest.id,
              date: selectedRequest.date,
              startTime: selectedRequest.startTime,
              endTime: selectedRequest.endTime,
              status: 'denied',
              comment: reason,
              type: selectedRequest.type
            }
          }),
          redirect: 'follow'
        };
  
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTimeStatus', requestOptions);
        const result = await response.json();
  
        if (result.error) {
          console.error('Error changing time status:', result.error);
        } else {
          const updatedAllEntries = allTimeEntries.map(entry => {
            if (entry.id === selectedRequest.id) {
              return { ...entry, status: 'denied', comment: reason };
            }
            return entry;
          });
          setAllTimeEntries(updatedAllEntries);
  
          const updatedPendingEntries = timeEntries.filter(entry => entry.id !== selectedRequest.id);
          setTimeEntries(updatedPendingEntries);
  
          const updatedDeviations = deviations.filter(entry => entry.id !== selectedRequest.id);
          setDeviations(updatedDeviations);
  
          const within15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) <= 15);
          const outside15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) > 15);

          setDeviationCounts({
            within: within15Minutes.length,
            outside: outside15Minutes.length,
            total: within15Minutes.length + outside15Minutes.length
          });
  
          setChangeRequests(prev => ({
            ...prev,
            denied: [...prev.denied, { ...selectedRequest, status: 'denied', comment: reason }],
            pending: prev.pending.filter(req => req.id !== selectedRequest.id)
          }));
  
          setSelectedRequest(null);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const updateTimeEntriesState = (id, newStatus) => {
    setAllTimeEntries(prevEntries =>
      prevEntries.map(entry =>
        entry.id === id || entry._id === id ? { ...entry, status: newStatus } : entry
      )
    );
  };
  
  const updateChangeRequestsState = (id, newStatus) => {
    setChangeRequests(prevRequests => ({
      ...prevRequests,
      pending: prevRequests.pending.filter(req => req._id !== id),
      approved: newStatus === 'approved' ? [...prevRequests.approved, { ...selectedRequest, status: 'approved' }] : prevRequests.approved,
      denied: newStatus === 'denied' ? [...prevRequests.denied, { ...selectedRequest, status: 'denied' }] : prevRequests.denied,
    }));
  };
  
  const updateDeviationsState = (id, newStatus) => {
    setDeviations(prevDeviations =>
      prevDeviations.map(entry =>
        entry.id === id || entry._id === id ? { ...entry, status: newStatus } : entry
      )
    );
  };

  const renderDayOverview = () => {
    const dailyGoal = getDailyGoalHours(selectedDay);
    let adjustedDailyGoal = dailyGoal;
    
    // const holiday = holidayPlan?.timeslots?.find(day => new Date(day.date).toISOString().split('T')[0] === selectedDay);
    // if (holiday && holiday.status !== 'Wochenende') {
    //   adjustedDailyGoal = 0;
    // }
  
    const diffHoursForDay = totalHoursForDay.totalMinutes / 60 - adjustedDailyGoal;
  
    return (
      <div className="grid grid-cols-4 gap-[16px] mb-[32px]">
        <div className="bg-[#E8EDFF] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
          <h3 className="text-[16px] font-[500] text-[#0000FF]">Tagesziel</h3>
          <p className="text-[36px] font-[600] text-[#0000FF]">{adjustedDailyGoal} Std</p>
        </div>
        <div className="bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
          <h3 className="text-[16px] font-[500] text-[#101828]">Erfasste Stunden</h3>
          <p className="text-[36px] font-[600] text-[#0000FF]">{totalHoursForDay.hours} Std {totalHoursForDay.minutes} Min</p>
        </div>
        <div className={`bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] text-red-600} style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }`}>
          <h3 className="text-[16px] font-[500] text-[#101828]">{diffHoursForDay < 0 ? 'Minusstunden' : 'Überstunden'}</h3>
          <p className="text-[36px] font-[600] text-red-600">{diffHoursForDay < 0 ? '-' : '+'} {Math.abs(diffHoursForDay).toFixed(2)} Std</p>
        </div>
      </div>
    );
  };

  const calculateMonthlyHours = (year, month) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    let totalHours = 0;

    console.log('daysInMonth',daysInMonth)
  
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month - 1, day);
      const weekday = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
  
      if (annualPlan[weekday]) {
        totalHours += annualPlan[weekday];
      }
    }
  
    return totalHours;
  };

  const renderMonthlyOverview = (month) => {
    const [year, monthNum] = month.split('-').map(Number);

    const monthlyTimes = allTimeEntries.filter(entry => entry.date.startsWith(month));

    const totalHours = calculateTotalHours(monthlyTimes);

    const monthlyTargetHours = calculateMonthlyHours(year, monthNum);

    const diffHours = (totalHours.totalMinutes / 60) - monthlyTargetHours;

    return (
        <div key={month} className="mt-4">
            <h2 className="text-xl font-bold mb-4">
                {new Date(`${month}-01`).toLocaleString('de-DE', { year: 'numeric', month: 'long' })}
            </h2>
            <div className="grid grid-cols-4 gap-[16px] mb-[32px]">
                <div className="bg-[#E8EDFF] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                    <h3 className="text-[16px] font-[500] text-[#0000FF]">Monatsziel</h3>
                    <p className="text-[36px] font-[600] text-[#0000FF]">{Math.floor(monthlyTargetHours)} Std</p>
                </div>
                <div className="bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                    <h3 className="text-[16px] font-[500] text-[#101828]">Erfasste Stunden</h3>
                    <p className="text-[36px] font-[600] text-[#0000FF]">{totalHours.hours} Std {totalHours.minutes} Min</p>
                </div>
                <div className={`bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] ${diffHours === 0 ? 'text-green-600' : 'text-red-600'}`} style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                    <h3 className="text-[16px] font-[500] text-[#101828]">{diffHours < 0 ? 'Minusstunden' : 'Überstunden'}</h3>
                    <p className="text-[36px] font-[600]">{diffHours < 0 ? '-' : '+'} {Math.abs(diffHours).toFixed(2)} Std</p>
                </div>
            </div>
        </div>
    );
  };

  const renderMonthlyOverviewWithMissedHours = (month) => {
    const [year, monthNum] = month.split('-').map(Number);
  
    const monthlyTimes = allTimeEntries.filter(entry => entry.date.startsWith(month));
  
    const totalHours = calculateTotalHours(monthlyTimes);
    const monthlyTargetHours = calculateMonthlyHours(year, monthNum);
  
    const missedHours = calculateMissedHours(month);
    const diffHours = (totalHours.totalMinutes / 60 + missedHours) - monthlyTargetHours;
  
    return (
      <div key={month} className="mt-4">
        <h2 className="text-xl font-bold mb-4">
          {new Date(`${month}-01`).toLocaleString('de-DE', { year: 'numeric', month: 'long' })}
        </h2>
        <div className="grid grid-cols-4 gap-[16px] mb-[32px]">
          <div className="bg-[#E8EDFF] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
            <h3 className="text-[16px] font-[500] text-[#0000FF]">Monatsziel</h3>
            <p className="text-[36px] font-[600] text-[#0000FF]">{Math.floor(monthlyTargetHours)} Std</p>
          </div>
          <div className="bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
            <h3 className="text-[16px] font-[500] text-[#101828]">Erfasste Stunden</h3>
            <p className="text-[36px] font-[600] text-[#0000FF]">{totalHours.hours} Std {totalHours.minutes} Min</p>
          </div>
          <div className={`bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] ${diffHours < 0 ? 'text-red-600' : 'text-green-600'}`} style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
            <h3 className="text-[16px] font-[500] text-[#101828]">{diffHours < 0 ? 'Minusstunden' : 'Überstunden'}</h3>
            <p className="text-[36px] font-[600]">{diffHours < 0 ? '-' : '+'} {Math.abs(diffHours).toFixed(2)} Std</p>
          </div>
        </div>
      </div>
    );
  };

  const renderAnnualOverview = () => {
    const currentYear = new Date().getFullYear();

    const allMonths = Array.from({ length: 12 }, (v, i) => {
      return `${currentYear}-${String(i + 1).padStart(2, '0')}`;
    });

    return (
      <div className="mt-4">
        {allMonths.map(month => renderMonthlyOverviewWithMissedHours(month))}
      </div>
    );
  };

  const getDailyGoalHours = (date) => {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayIndex = new Date(date).getDay();
    return annualPlan[daysOfWeek[dayIndex]] || 0;
  };

  const getWeekdayInGerman = (date) => {
    const daysOfWeek = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const dayIndex = new Date(date).getDay();
    return daysOfWeek[dayIndex];
  };

  const handleApproveAllWithinDeviation = async () => {
    const withinDeviationEntries = deviations.filter(d => Math.abs(d.deviation) <= 15);
    const approvedChangeRequests = [];
    const approvedTimeEntries = [];
    let updatedDeviations = [...deviations];
  
    for (let entry of withinDeviationEntries) {
      console.log("Processing entry:", entry);
  
      if (entry.type === 'change request') {
        const changeRequestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            changeRequestId: entry._id,
            status: 'approved'
          }),
          redirect: 'follow'
        };
  
        const changeTimeOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            employeeId: entry.employeeId,
            timeSlotData: {
              id: entry.timeId,
              date: entry.newStartTime.split('T')[0],
              startTime: entry.newStartTime.split('T')[1].substring(0, 5),
              endTime: entry.newEndTime.split('T')[1].substring(0, 5),
              status: 'approved'
            }
          }),
          redirect: 'follow'
        };
  
        try {
          const changeRequestResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/editChangeRequest', changeRequestOptions);
          const changeRequestResult = await changeRequestResponse.json();
  
          if (changeRequestResult.error) {
            console.error('Error approving change request:', changeRequestResult.error);
          } else {
            approvedChangeRequests.push({ ...entry, status: 'approved' });
  
            const changeTimeResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTime', changeTimeOptions);
            const changeTimeResult = await changeTimeResponse.json();
  
            if (changeTimeResult.error) {
              console.error('Error changing time:', changeTimeResult.error);
            } else {
              console.log("Approved change request and corresponding time entry:", entry);
              const approvedEntry = {
                ...entry,
                id: entry.timeId,
                startTime: entry.newStartTime.split('T')[1].substring(0, 5),
                endTime: entry.newEndTime.split('T')[1].substring(0, 5),
                status: 'approved'
              };
              approvedTimeEntries.push(approvedEntry);
              updatedDeviations = updatedDeviations.filter(d => d._id !== entry._id);
  
              setAllTimeEntries(prevEntries => prevEntries.map(e => 
                e.id === entry.timeId ? { ...e, startTime: entry.newStartTime.split('T')[1].substring(0, 5), endTime: entry.newEndTime.split('T')[1].substring(0, 5), status: 'approved' } : e
              ));
              setTimeEntries(prevEntries => prevEntries.map(e => 
                e.id === entry.timeId ? { ...e, startTime: entry.newStartTime.split('T')[1].substring(0, 5), endTime: entry.newEndTime.split('T')[1].substring(0, 5), status: 'approved' } : e
              ));
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
  
      if (entry.type === 'time') {
        try {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Request-Headers': '*',
            },
            body: JSON.stringify({
              token,
              employeeId: entry.employeeId || selectedEmployee.employeeId,
              timeSlotData: {
                id: entry.id,
                date: entry.date,
                startTime: entry.startTime,
                endTime: entry.endTime,
                status: 'approved',
              }
            }),
            redirect: 'follow'
          };
  
          const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTimeStatus', requestOptions);
          const result = await response.json();
  
          if (result.error) {
            console.error('Error changing time status:', result.error);
          } else {
            console.log("Approved time entry:", entry);
            approvedTimeEntries.push({ ...entry, status: 'approved' });
            updatedDeviations = updatedDeviations.filter(d => d.id !== entry.id);
  
            setAllTimeEntries(prevEntries => prevEntries.map(e => 
              e.id === entry.id ? { ...e, startTime: entry.startTime, endTime: entry.endTime, status: 'approved' } : e
            ));
            setTimeEntries(prevEntries => prevEntries.map(e => 
              e.id === entry.id ? { ...e, startTime: entry.startTime, endTime: entry.endTime, status: 'approved' } : e
            ));
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  
    const within15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) <= 15).length;
    const outside15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) > 15).length;
    
    setDeviationCounts({
      within: within15Minutes,
      outside: outside15Minutes,
      total: within15Minutes + outside15Minutes
    });
  
    setDeviations(updatedDeviations);
    
    console.log("Approved change requests:", approvedChangeRequests);
    console.log("Approved time entries:", approvedTimeEntries);
    
    setChangeRequests(prev => ({
      ...prev,
      approved: [...prev.approved, ...approvedChangeRequests],
      pending: prev.pending.filter(req => !withinDeviationEntries.some(e => e._id === req._id)),
    }));
  
    setSelectedRequest(null);
    setSelectedEntries([]);
  };

  const combinedDeviations = [
    ...deviations,
  ];

  const handleApproveSelectedEntries = async () => {
    const approvedChangeRequests = [];
    const approvedTimeEntries = [];
    let updatedDeviations = [...deviations];
  
    for (let entry of selectedEntries) {
      console.log("Processing entry:", entry);
  
      if (entry.type === 'change request') {
        const changeRequestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            changeRequestId: entry._id,
            status: 'approved'
          }),
          redirect: 'follow'
        };
  
        const changeTimeOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            employeeId: entry.employeeId,
            timeSlotData: {
              id: entry.timeId,
              date: entry.newStartTime.split('T')[0],
              startTime: entry.newStartTime.split('T')[1].substring(0, 5),
              endTime: entry.newEndTime.split('T')[1].substring(0, 5),
              status: 'approved'
            }
          }),
          redirect: 'follow'
        };
  
        try {
          const changeRequestResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/editChangeRequest', changeRequestOptions);
          const changeRequestResult = await changeRequestResponse.json();
  
          if (changeRequestResult.error) {
            console.error('Error approving change request:', changeRequestResult.error);
          } else {
            approvedChangeRequests.push({ ...entry, status: 'approved' });
  
            const changeTimeResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTime', changeTimeOptions);
            const changeTimeResult = await changeTimeResponse.json();
  
            if (changeTimeResult.error) {
              console.error('Error changing time:', changeTimeResult.error);
            } else {
              console.log("Approved change request and corresponding time entry:", entry);
              const approvedEntry = {
                ...entry,
                id: entry.timeId,
                startTime: entry.newStartTime.split('T')[1].substring(0, 5),
                endTime: entry.newEndTime.split('T')[1].substring(0, 5),
                status: 'approved'
              };
              approvedTimeEntries.push(approvedEntry);
              updatedDeviations = updatedDeviations.filter(d => d._id !== entry._id);
  
              setAllTimeEntries(prevEntries => prevEntries.map(e => 
                e.id === entry.timeId ? { ...e, startTime: entry.newStartTime.split('T')[1].substring(0, 5), endTime: entry.newEndTime.split('T')[1].substring(0, 5), status: 'approved' } : e
              ));
              setTimeEntries(prevEntries => prevEntries.map(e => 
                e.id === entry.timeId ? { ...e, startTime: entry.newStartTime.split('T')[1].substring(0, 5), endTime: entry.newEndTime.split('T')[1].substring(0, 5), status: 'approved' } : e
              ));
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
  
      if (entry.type === 'time') {
        try {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Request-Headers': '*',
            },
            body: JSON.stringify({
              token,
              employeeId: entry.employeeId || selectedEmployee.employeeId,
              timeSlotData: {
                id: entry.id,
                date: entry.date,
                startTime: entry.startTime,
                endTime: entry.endTime,
                status: 'approved',
              }
            }),
            redirect: 'follow'
          };
  
          const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTimeStatus', requestOptions);
          const result = await response.json();
  
          if (result.error) {
            console.error('Error changing time status:', result.error);
          } else {
            console.log("Approved time entry:", entry);
            approvedTimeEntries.push({ ...entry, status: 'approved' });
            updatedDeviations = updatedDeviations.filter(d => d.id !== entry.id);
  
            setAllTimeEntries(prevEntries => prevEntries.map(e => 
              e.id === entry.id ? { ...e, startTime: entry.startTime, endTime: entry.endTime, status: 'approved' } : e
            ));
            setTimeEntries(prevEntries => prevEntries.map(e => 
              e.id === entry.id ? { ...e, startTime: entry.startTime, endTime: entry.endTime, status: 'approved' } : e
            ));
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  
    const within15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) <= 15).length;
    const outside15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) > 15).length;
    
    setDeviationCounts({
      within: within15Minutes,
      outside: outside15Minutes,
      total: within15Minutes + outside15Minutes
    });
  
    setDeviations(updatedDeviations);
    
    console.log("Approved change requests:", approvedChangeRequests);
    console.log("Approved time entries:", approvedTimeEntries);
    
    setChangeRequests(prev => ({
      ...prev,
      approved: [...prev.approved, ...approvedChangeRequests],
      pending: prev.pending.filter(req => !selectedEntries.some(e => e._id === req._id)),
    }));
  
    setSelectedRequest(null);
    setSelectedEntries([]);
  };

  const handleRejectSelectedEntries = async () => {
    const deniedChangeRequests = [];
    const deniedTimeEntries = [];
    let updatedDeviations = [...deviations];
  
    for (let entry of selectedEntries) {
      console.log("Processing entry:", entry);
  
      if (entry.type === 'change request') {
        const changeRequestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            changeRequestId: entry._id,
            status: 'denied',
            leadResponse: true
          }),
          redirect: 'follow'
        };
  
        try {
          const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/editChangeRequest', changeRequestOptions);
          const result = await response.json();
  
          if (result.error) {
            console.error('Error rejecting change request:', result.error);
          } else {
            deniedChangeRequests.push({ ...entry, status: 'denied' });
            updatedDeviations = updatedDeviations.filter(d => d._id !== entry._id);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
  
      if (entry.type === 'time') {
        try {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Request-Headers': '*',
            },
            body: JSON.stringify({
              token,
              employeeId: entry.employeeId || selectedEmployee.employeeId,
              timeSlotData: {
                id: entry.id,
                date: entry.date,
                startTime: entry.startTime,
                endTime: entry.endTime,
                status: 'denied',
                type: entry.type
              }
            }),
            redirect: 'follow'
          };
  
          const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTimeStatus', requestOptions);
          const result = await response.json();
  
          if (result.error) {
            console.error('Error changing time status:', result.error);
          } else {
            deniedTimeEntries.push({ ...entry, status: 'denied' });
            updatedDeviations = updatedDeviations.filter(d => d.id !== entry.id);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  
    const updateEntries = (prevEntries) => {
      const newEntries = prevEntries.map(e => 
        deniedTimeEntries.some(a => a.id === e.id) 
        ? { ...e, status: 'denied' }
        : e
      );
      return newEntries;
    };
  
    setAllTimeEntries(updateEntries);
    setTimeEntries(updateEntries);
  
    const within15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) <= 15).length;
    const outside15Minutes = updatedDeviations.filter(d => Math.abs(d.deviation) > 15).length;
  
    setDeviationCounts({
      within: within15Minutes,
      outside: outside15Minutes,
      total: within15Minutes + outside15Minutes
    });
  
    setDeviations(updatedDeviations);
  
    console.log("Denied change requests:", deniedChangeRequests);
    console.log("Denied time entries:", deniedTimeEntries);
  
    setChangeRequests(prev => ({
      ...prev,
      denied: [...prev.denied, ...deniedChangeRequests],
      pending: prev.pending.filter(req => !selectedEntries.some(e => e._id === req._id)),
    }));
  
    setSelectedRequest(null);
    setSelectedEntries([]);
  };
  
  return (
    <div className="bg-gray-50 min-h-screen pt-[48px] px-[83px]">
      {loading ? (
        <div className="flex justify-center items-center h-[75vh]">
          <Oval
            height={80}
            width={80}
            color="#0000FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#0000FF"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <RejectionDialog
            isOpen={isRejectionDialogOpen}
            onClose={() => setIsRejectionDialogOpen(false)}
            onSave={handleSaveRejectionReason}
          />
          <WarningDialog
            isOpen={isWarningDialogOpen}
            onClose={() => setIsWarningDialogOpen(false)}
            onSave={handleSaveWarningReason}
          />
          <AbsenceDialog
            isOpen={isAbsenceDialogOpen}
            onClose={() => setIsAbsenceDialogOpen(false)}
            onSave={handleSaveAbsence}
          />
          <EditTimeDialog
            isOpen={isEditTimeDialogOpen}
            onClose={() => setIsEditTimeDialogOpen(false)}
            onSave={handleEditTime}
            initialData={editData || { date: '', startTime: '', endTime: '', pause: '' }}
          />
          <div className="mb-[32px]">
            <h1 className="text-2xl font-bold">
              {viewMode === 'year'
                ? 'Jahresübersicht'
                : viewMode === 'day'
                ? 'Tagesübersicht'
                : 'Monatsübersicht'}
            </h1>
            <p className="text-sm text-gray-600">Meine Zeiten &gt; Aktueller Stundensoll</p>
          </div>
          <div className="mb-[32px]">
            <div className="flex items-end">
              <div className="flex-grow">
                <label htmlFor="employee-select" className="block text-gray-700 mb-2">
                  Zeitübersicht für
                </label>
                <select
                  id="employee-select"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={selectedEmployee ? selectedEmployee.email : ''}
                  onChange={handleEmployeeChange}
                >
                  <option value="" disabled>
                    Wählen Sie einen Mitarbeiter
                  </option>
                  {employeeList.map((employee) => (
                    <option key={employee._id} value={employee.email}>
                      {employee.lastName} {employee.firstName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="ml-4 w-[150px]">
                <label htmlFor="view-select" className="block text-gray-700 mb-2">
                  Ansicht:
                </label>
                <select
                  id="view-select"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={viewMode}
                  onChange={handleViewChange}
                >
                  <option value="month">Monat</option>
                  <option value="year">Jahr</option>
                  <option value="day">Tag</option>
                  <option value="liveList">Live Liste</option>
                </select>
              </div>
            </div>
          </div>
          {selectedEmployee && viewMode === 'month' && (
            <div className="flex-grow mb-[32px]">
              <label htmlFor="month-select" className="block mb-2 text-gray-700">
                Übersicht für den Monat:
              </label>
              <select
                id="month-select"
                className="w-full p-2 border border-gray-300 rounded"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option value="" disabled>
                  Wählen Sie einen Monat
                </option>
                {(() => {
                  const currentMonth = new Date().toISOString().substring(0, 7);
                  const currentMonthIndex = months.indexOf(currentMonth);
                  const sortedMonths = currentMonthIndex !== -1
                    ? [...months.slice(currentMonthIndex), ...months.slice(0, currentMonthIndex)]
                    : months;

                  return sortedMonths.map((month) => (
                    <option key={month} value={month}>
                      {new Date(`${month}-01`).toLocaleString('de-DE', { year: 'numeric', month: 'long' })}
                    </option>
                  ));
                })()}
              </select>
            </div>
          )}
          {selectedEmployee && viewMode === 'day' && (
            <div className="flex-grow mb-[32px]">
              <label htmlFor="day-select" className="block mb-2 text-gray-700">
                Übersicht für den Tag:
              </label>
              <select
                id="day-select"
                className="w-full p-2 border border-gray-300 rounded"
                value={selectedDay}
                onChange={handleDayChange}
              >
                <option value="" disabled>
                  Wählen Sie einen Tag
                </option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {getWeekdayInGerman(day)}, {new Date(day).toLocaleString('de-DE', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedDay && viewMode === 'day' && (
            <div className="py-4">
            {renderDayOverview()}
            <div className="flex">
              <div className={`flex-grow ${selectedEntries.length > 0 || selectedRequest ? 'mr-4' : ''}`}>
                <div className="grid grid-cols-2 gap-4">
                  {renderRosterTable(filteredRosterData)}
                  {renderEntriesTable([...categorizedEntries.pending, ...categorizedEntries.approved, ...categorizedEntries.denied])}
                </div>
              </div>
              {(selectedEntries.length > 0 || selectedRequest) && (
                <div className="flex flex-col w-[300px]">
                  {selectedEntries.length > 0 ? (
                    <div className="bg-white rounded-lg shadow-lg w-full min-h-[200px] mb-[8px]">
                      <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">Zeitdetails</h1>
                      <div className='p-4'>
                        <p className='font-bold mb-[8px]'>{selectedEntries.length} Zeiten ausgewählt</p>
                        <button className="bg-[#96DB3F] text-white py-2 px-4 rounded-lg mb-[8px]" onClick={handleApproveSelectedEntries}>Zeiten bestätigen</button>
                        <button className="bg-[#FF5252] text-white py-2 px-4 rounded-lg mb-[16px]" onClick={handleRejectSelectedEntries}>Zeiten ablehnen</button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {selectedRequest && (
                        <>
                          <div className="bg-white rounded-lg shadow-lg w-full min-h-[550px] mb-[8px]">
                            <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">
                              {selectedRequest.type === 'time' ? 'Zeitdetails' : 'Änderungsdetails'}
                            </h1>
                            <div className="p-4">
                              <p className="font-bold mb-[8px]">
                                {new Date(
                                  selectedRequest.date || selectedRequest.newStartTime
                                ).toLocaleDateString('de-DE')}
                              </p>
                              <p className="mb-[8px]">
                                Start: {selectedRequest.startTime || selectedRequest.newStartTime.split('T')[1].substring(0, 5)}{' '}
                                Ende: {selectedRequest.endTime || selectedRequest.newEndTime.split('T')[1].substring(0, 5)}
                              </p>
                              {selectedRequest.type === 'time' && (
                                <p className="mb-[8px]">
                                  Pause: {selectedRequest.pause}
                                </p>
                              )}
                              <p>Gesamt:</p>
                              <p
                                className={`py-[8px] text-[#0000FF] font-bold text-[30px] leading-[38px] ${
                                  selectedRequest.deviationMessage && 'mb-[12px]'
                                }`}
                              >
                                {getTimeDifference(
                                  selectedRequest.startTime || selectedRequest.newStartTime.split('T')[1].substring(0, 5),
                                  selectedRequest.endTime || selectedRequest.newEndTime.split('T')[1].substring(0, 5),
                                  selectedRequest.pause
                                )}
                              </p>
                              {selectedRequest.deviationMessage && (
                                <p className="pb-[16px] border-b">{selectedRequest.deviationMessage}</p>
                              )}
                              <p className="mb-[8px]" onClick={() => console.log(selectedRequest)}>
                                Kunde:{' '}
                                {selectedRequest.type === 'change request'
                                  ? (clients.find(client => client._id === selectedRequest.customer) && Object.keys(clients.find(client => client._id === selectedRequest.customer)).length ? clients.find(client => client._id === selectedRequest.customer).company.name : '')
                                  : (clients.find(client => client._id === selectedRequest.customerId) && Object.keys(clients.find(client => client._id === selectedRequest.customerId)).length ? clients.find(client => client._id === selectedRequest.customerId).company.name : '')
                                }
                              </p>
                              <p className="mb-[8px]">
                                Projekt:{' '}
                                {selectedRequest.type === 'change request'
                                  ? (projects.find(project => project._id === selectedRequest.project) && Object.keys(projects.find(project => project._id === selectedRequest.project)).length ? projects.find(project => project._id === selectedRequest.project).name : '')
                                  : (projects.find(project => project._id === selectedRequest.projectId) && Object.keys(projects.find(project => project._id === selectedRequest.projectId)).length ? projects.find(project => project._id === selectedRequest.projectId).name : '')
                                }
                              </p>
                              <p className="mb-[38px]">
                                Tätigkeit:{' '}
                                {selectedRequest.type === 'change request'
                                  ? (tasks.find(task => task._id === selectedRequest.task) && Object.keys(tasks.find(task => task._id === selectedRequest.task)).length ? tasks.find(task => task._id === selectedRequest.task).name : '')
                                  : (tasks.find(task => task._id === selectedRequest.taskId) && Object.keys(tasks.find(task => task._id === selectedRequest.taskId)).length ? tasks.find(task => task._id === selectedRequest.taskId).name : '')
                                }
                              </p>
                              {selectedRequest.status === 'pending' && (
                                <div className="mt-4 flex flex-col">
                                  <button
                                    className="bg-[#96DB3F] text-white py-2 px-4 rounded-lg mb-[8px]"
                                    onClick={() => handleApprove(false, '', false)}
                                  >
                                    Zeit bestätigen
                                  </button>
                                  <button
                                    className="bg-[#FF5252] text-white py-2 px-4 rounded-lg mb-[16px]"
                                    onClick={handleReject}
                                  >
                                    Zeit ablehnen
                                  </button>
                                </div>
                              )}
                              {
                                selectedRequest.type === "time" &&
                                  <div className="mt-4 flex flex-col">
                                    <button
                                      className="bg-[#0000FF] text-white py-2 px-4 rounded-lg mb-[8px]"
                                      onClick={() => {
                                        setEditData({
                                          date: selectedRequest.date || selectedRequest.newStartTime.split('T')[0],
                                          startTime:
                                            selectedRequest.startTime || selectedRequest.newStartTime.split('T')[1].substring(0, 5),
                                            endTime: selectedRequest.endTime || selectedRequest.newEndTime.split('T')[1].substring(0, 5),
                                            pause: selectedRequest.pause
                                          });
                                        setIsEditTimeDialogOpen(true);
                                      }}
                                    >
                                      Zeit bearbeiten
                                    </button>
                                  </div>
                              }
                            </div>
                          </div>
                          <div className="bg-white rounded-lg shadow-lg w-full">
                            <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">Anmerkungen</h1>
                            <p onClick={() => console.log(selectedRequest)} className="p-4">
                              {selectedRequest?.employeeResponse ? selectedRequest.employeeResponse : 'Keine Anmerkung'}
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          )}
          {selectedEmployee && viewMode === 'month' && selectedMonth && (
            <div className="mt-4 grid grid-cols-4 gap-[16px] mb-[32px]">
              <div className="bg-[#E8EDFF] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                <h3 className="text-[16px] font-[500] text-[#0000FF]">Monatsziel</h3>
                <p className="text-[36px] font-[600] text-[#0000FF]">{monthlyTargetHours} Std</p>
              </div>
              <div className="bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                <h3 className="text-[16px] font-[500] text-[#101828]">Erfasste Stunden</h3>
                <p className="text-[36px] font-[600] text-[#0000FF]">{totalHours.hours} Std {totalHours.minutes} Min</p>
              </div>
              <div className={`bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] text-red-600`} style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                <h3 className="text-[16px] font-[500] text-[#101828]">{(totalHours.totalMinutes / 60 + missedHours) - monthlyTargetHours < 0 ? 'Minusstunden' : 'Überstunden'}</h3>
                <p className="text-[36px] font-[600] text-red-600">{(totalHours.totalMinutes / 60 + missedHours) - monthlyTargetHours < 0 ? '-' : '+'} {Math.abs((totalHours.totalMinutes / 60 + missedHours) - monthlyTargetHours).toFixed(2)} Std</p>
              </div>
            </div>
          )}
          {/* {viewMode === 'year' && (
            <div>
              {months.map(month => renderMonthlyOverview(month))}
            </div>
          )} */}
          {selectedMonth && viewMode === 'month' && (
            <>
              <div className="grid grid-cols-3 gap-[16px] bg-gray-50">
                <button
                  className={`p-2 h-[44px] font-[500] rounded ${
                    selectedTab === 'pending' ? 'bg-[#EAEAED] text-[#424B58]' : 'bg-[#FCFCFC] text-[#424B58]'
                  }`}
                  onClick={() => {
                    setSelectedFilter(null)
                    handleTabClick('pending');
                    setFilteredPendingView(null);
                  }}
                >
                  Nicht geprüfte Zeiten: {categorizedEntries.pending.length}
                </button>
                <button
                  className={`p-2 h-[44px] font-[500] rounded ${
                    selectedTab === 'approved' ? 'bg-[#EAEAED] text-[#424B58]' : 'bg-[#FCFCFC] text-[#424B58]'
                  }`}
                  onClick={() => {
                    handleTabClick('approved');
                    setFilteredPendingView(null);
                  }}
                >
                  Bestätigte Zeiten: {categorizedEntries.approved.length}
                </button>
                <button
                  className={`p-2 h-[44px] font-[500] rounded ${
                    selectedTab === 'denied' ? 'bg-[#EAEAED] text-[#424B58]' : 'bg-[#FCFCFC] text-[#424B58]'
                  }`}
                  onClick={() => {
                    handleTabClick('denied');
                    setFilteredPendingView(null);
                  }}
                >
                  Abgelehnte Zeiten: {categorizedEntries.denied.length}
                </button>
              </div>
              <div className='bg-[#EAEAED] -mx-[83px] px-[83px] pb-[25px]'>
              <div className="pt-[32px] flex justify-between mb-[32px]">
              <div className="flex gap-[16px]">
                {selectedTab === 'pending' ? (
                  <>
                    <button
                      className={`p-2 h-[44px] w-[222px] rounded border-2 shadow-sm ${
                        selectedFilter === 'within' 
                          ? 'bg-white text-[#0000FF] border-[#0000FF]' 
                          : 'bg-white text-[#A7B3C4] border-white'
                      }`}
                      onClick={() => {
                        setFilteredPendingView('within');
                        setSelectedFilter('within');
                      }}
                    >
                      Unauffällige Zeiten: {deviationCounts.within}
                    </button>
                    <button
                      className={`p-2 h-[44px] w-[222px] rounded border-2 shadow-sm ${
                        selectedFilter === 'outside' 
                          ? 'bg-white text-[#0000FF] border-[#0000FF]' 
                          : 'bg-white text-[#A7B3C4] border-white'
                      }`}
                      onClick={() => {
                        setFilteredPendingView('outside');
                        setSelectedFilter('outside');
                      }}
                    >
                      Auffällige Zeiten: {deviationCounts.outside}
                    </button>
                    <button
                      className={`p-2 h-[44px] w-[222px] rounded border-2 shadow-sm ${
                        selectedFilter === 'all' 
                          ? 'bg-white text-[#0000FF] border-[#0000FF]' 
                          : 'bg-white text-[#A7B3C4] border-white'
                      }`}
                      onClick={() => {
                        setFilteredPendingView('all');
                        setSelectedFilter('all');
                      }}
                    >
                      Alle: {deviationCounts.total}
                    </button>
                  </>
                ) : (
                  <div className="h-[0px] w-[666px]" />
                )}
              </div>
              {selectedTab === 'pending' && (
                <button
                  className="p-2 h-[44px] w-[300px] rounded bg-[#96DB3F] text-white"
                  onClick={handleApproveAllWithinDeviation}
                >
                  Alle unauffälligen Zeiten bestätigen
                </button>
              )}
            </div>
              <div className="flex">
                <div className="flex-grow">
                  {renderEntriesTable(
                    filteredPendingView
                      ? deviations.filter((entry) => {
                          if (filteredPendingView === 'within')
                            return Math.abs(entry.deviation) <= 15;
                          if (filteredPendingView === 'outside')
                            return Math.abs(entry.deviation) > 15;
                          return true;
                        })
                      : categorizedEntries[selectedTab]
                  )}
                </div>
                <div className="flex flex-col ml-4">
                  {selectedEntries.length > 0 ? (
                    <div className="bg-white rounded-lg shadow-lg w-[300px] min-h-[200px] mb-[8px]">
                      <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">Zeitdetails</h1>
                      <div className='p-4'>
                        <p className='font-bold mb-[8px]'>{selectedEntries.length} Zeiten ausgewählt</p>
                        <button className="bg-[#96DB3F] text-white py-2 px-4 rounded-lg mb-[8px]" onClick={handleApproveSelectedEntries}>Zeiten bestätigen</button>
                        <button className="bg-[#FF5252] text-white py-2 px-4 rounded-lg mb-[16px]" onClick={handleRejectSelectedEntries}>Zeiten ablehnen</button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {selectedRequest && (
                        <>
                          <div className="bg-white rounded-lg shadow-lg w-[300px] min-h-[550px] mb-[8px]">
                            <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">
                              {selectedRequest.type === 'time' ? 'Zeitdetails' : 'Änderungsdetails'}
                            </h1>
                            <div className="p-4">
                              <p className="font-bold mb-[8px]">
                                {new Date(
                                  selectedRequest.date || selectedRequest.newStartTime
                                ).toLocaleDateString('de-DE')}
                              </p>
                              <p className="mb-[8px]">
                                Start: {selectedRequest.startTime || selectedRequest.newStartTime.split('T')[1].substring(0, 5)}{' '}
                                Ende: {selectedRequest.endTime || selectedRequest.newEndTime.split('T')[1].substring(0, 5)}
                              </p>
                              {selectedRequest.type === 'time' && (
                                <p className="mb-[8px]">
                                  Pause: {selectedRequest.pause}
                                </p>
                              )}
                              <p>Gesamt:</p>
                              <p
                                className={`py-[8px] text-[#0000FF] font-bold text-[30px] leading-[38px] ${
                                  selectedRequest.deviationMessage && 'mb-[12px]'
                                }`}
                              >
                                {getTimeDifference(
                                  selectedRequest.startTime || selectedRequest.newStartTime.split('T')[1].substring(0, 5),
                                  selectedRequest.endTime || selectedRequest.newEndTime.split('T')[1].substring(0, 5),
                                  selectedRequest.pause
                                )}
                              </p>
                              {selectedRequest.deviationMessage && (
                                <p className="pb-[16px] border-b">{selectedRequest.deviationMessage}</p>
                              )}
                              <p className="mb-[8px]" onClick={() => console.log(selectedRequest)}>
                                Kunde:{' '}
                                {selectedRequest.type === 'change request'
                                  ? (clients.find(client => client._id === selectedRequest.customer) && Object.keys(clients.find(client => client._id === selectedRequest.customer)).length ? clients.find(client => client._id === selectedRequest.customer).company.name : '')
                                  : (clients.find(client => client._id === selectedRequest.customerId) && Object.keys(clients.find(client => client._id === selectedRequest.customerId)).length ? clients.find(client => client._id === selectedRequest.customerId).company.name : '')
                                }
                              </p>
                              <p className="mb-[8px]">
                                Projekt:{' '}
                                {selectedRequest.type === 'change request'
                                  ? (projects.find(project => project._id === selectedRequest.project) && Object.keys(projects.find(project => project._id === selectedRequest.project)).length ? projects.find(project => project._id === selectedRequest.project).name : '')
                                  : (projects.find(project => project._id === selectedRequest.projectId) && Object.keys(projects.find(project => project._id === selectedRequest.projectId)).length ? projects.find(project => project._id === selectedRequest.projectId).name : '')
                                }
                              </p>
                              <p className="mb-[38px]">
                                Tätigkeit:{' '}
                                {selectedRequest.type === 'change request'
                                  ? (tasks.find(task => task._id === selectedRequest.task) && Object.keys(tasks.find(task => task._id === selectedRequest.task)).length ? tasks.find(task => task._id === selectedRequest.task).name : '')
                                  : (tasks.find(task => task._id === selectedRequest.taskId) && Object.keys(tasks.find(task => task._id === selectedRequest.taskId)).length ? tasks.find(task => task._id === selectedRequest.taskId).name : '')
                                }
                              </p>
                              {selectedRequest.status === 'pending' && (
                                <div className="mt-4 flex flex-col">
                                  <button
                                    className="bg-[#96DB3F] text-white py-2 px-4 rounded-lg mb-[8px]"
                                    onClick={() => handleApprove(false, '', false)}
                                  >
                                    Zeit bestätigen
                                  </button>
                                  <button
                                    className="bg-[#FF5252] text-white py-2 px-4 rounded-lg mb-[16px]"
                                    onClick={handleReject}
                                  >
                                    Zeit ablehnen
                                  </button>
                                </div>
                              )}
                              {
                                selectedRequest.type === "time" &&
                                  <div className="mt-4 flex flex-col">
                                    <button
                                      className="bg-[#0000FF] text-white py-2 px-4 rounded-lg mb-[8px]"
                                      onClick={() => {
                                        setEditData({
                                          date: selectedRequest.date || selectedRequest.newStartTime.split('T')[0],
                                          startTime:
                                            selectedRequest.startTime || selectedRequest.newStartTime.split('T')[1].substring(0, 5),
                                          endTime: selectedRequest.endTime || selectedRequest.newEndTime.split('T')[1].substring(0, 5),
                                          pause: selectedRequest.pause
                                        });
                                        setIsEditTimeDialogOpen(true);
                                      }}
                                    >
                                      Zeit bearbeiten
                                    </button>
                                  </div>
                              }
                            </div>
                          </div>
                          <div className="bg-white rounded-lg shadow-lg w-full">
                            <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">Anmerkungen</h1>
                            <p className="p-4">
                              {selectedRequest?.employeeResponse ? selectedRequest.employeeResponse : 'Keine Anmerkung'}
                            </p>
                            {changeRequests?.pending
                              .filter(req => req.timeId === selectedRequest.id).map(changeRequest => (
                              <p 
                                key={changeRequest._id} 
                                className="text-blue-500 underline cursor-pointer p-4"
                                onClick={() => {
                                  changeRequest.type = 'change request'
                                  handleRequestClick(changeRequest)
                                }}
                              >
                                1 Änderungsanfrage vom {new Date(changeRequest.newStartTime || changeRequest.date).toLocaleDateString('de-DE')}
                              </p>
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              </div>
            </>
          )}
          {viewMode === 'year' && renderAnnualOverview()}
          {viewMode === 'liveList' && renderLiveList()}
          {/* {viewMode === 'day' && renderDayOverview()} */}
        </>
      )}
    </div>
  );
};

export default TimeManagement